import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const Input = ({ label, type, required, pattern, name }) => {
  return (
    <Root type={type}>
      <label>{label}</label>
      {type === 'multiline' ? (
        <textarea rows={3} required={required} name={name} />
      ) : (
        <input type={type} required={required} pattern={pattern} name={name} />
      )}
    </Root>
  );
};

export default Input;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${vwMobile(24)};
  label {
    margin-bottom: ${vwMobile(4)};
    font-size: ${vwMobile(16)};
    line-height: 1.5;
    font-weight: 300;
  }
  input {
    border: 1px solid ${({ theme }) => theme.colorCharcoal};
    height: ${vwMobile(52)};
    padding: ${vwMobile(10)};
    font-size: ${vwMobile(16)};
  }
  textarea {
    border: 1px solid ${({ theme }) => theme.colorCharcoal};
    font-size: ${vwMobile(16)};
    padding: ${vwMobile(10)};

    height: ${vwMobile(132)};
    line-height: 1.77;
  }
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${vwTablet(24)};
    margin-right: ${props => (props.type === 'multiline' ? 0 : vwTablet(21))};
    width: ${props =>
      props.type === 'multiline' ? vwTablet(465) : vwTablet(222)};
    font-size: ${vwTablet(16)};
    &:nth-child(2n) {
      margin-right: 0;
    }
    label {
      font-size: ${vwTablet(16)};
      margin-bottom: ${vwTablet(4)};
    }
    input {
      height: ${vwTablet(52)};
      font-size: ${vwTablet(16)};
      padding: ${vwTablet(10)};
    }
    textarea {
      margin-bottom: ${vwTablet(8)};
      font-size: ${vwTablet(16)};
      padding: ${vwTablet(10)};
    }
  }
  @media (min-width: ${media.mdup}) {
    margin-bottom: ${vw(29)};
    margin-right: ${props => (props.type === 'multiline' ? 0 : vw(27))};
    width: ${props => (props.type === 'multiline' ? vw(657) : vw(315))};
    font-size: ${vw(16)};
    label {
      font-size: ${vw(16)};
      margin-bottom: ${vw(5)};
    }
    input {
      height: ${vw(56)};
      font-size: ${vw(16)};
      padding: ${vw(10)};
    }
    textarea {
      margin-bottom: ${vw(30)};
      font-size: ${vw(16)};
      padding: ${vw(10)};
      height: ${vw(260)};
    }
  }
`;
