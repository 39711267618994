import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import ContactInfo from './sections/ContactInfo';
import Careers from './sections/Careers';
import Map from './sections/Map';
import PageLayout from 'components/PageLayout';
import Footer from 'components/Footer';
class GetInTouch extends Component {
  render() {
    return (
      <PageLayout>
        <Helmet>
          <meta
            name="description"
            content="Contact information for Sunwins Holdings."
          />
          <title>Sunwins | Contact Us</title>
        </Helmet>
        <ContactInfo />
        <Careers />
        <Map />
        <Footer />
      </PageLayout>
    );
  }
}

export default GetInTouch;
