import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const PolygonText = ({ children, textBackground, textAlign }) => {
  return (
    <Root
      background={require('src/assets/images/about/diamond.png')}
      textBackground={textBackground}
      textAlign={textAlign}
    >
      <TextContainer>{children} </TextContainer>
    </Root>
  );
};

export default PolygonText;

const Root = styled.div`
  background: ${props => `url(${props.background})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: ${vwMobile(376.3)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  margin-bottom: ${vwMobile(40.7)};
  h1 {
    background: ${props =>
      props.textBackground ? props.textBackground : 'white'};
    padding: ${vwMobile(20)} 0;
  }
  @media (min-width: ${media.xsup}) {
    bottom: ${vwTablet(40.7)};
    margin-bottom: 0;
    position: absolute;
    right: ${vwTablet(50)};
    height: ${vwTablet(376.3)};
  }
  @media (min-width: ${media.mdup}) {
    bottom: ${vw(46)};
    right: ${vw(105)};
    height: ${vw(523)};
  }
`;

const TextContainer = styled.div`
@media (min-width: ${media.mdup}) {
    display: block;
    padding-top: ${vw(38)};
    padding-bottom: ${vw(38)};
    padding-left: ${vw(54)};
    padding-right: ${vw(54)};
    width: ${vw(575)};
    h1 {
      padding: 0;
      margin-bottom: ${vw(17)};
      text-align: right;
      &:first-of-type {
        padding-top: 0;
        text-align: center;
      }
      &:last-of-type {
        padding-bottom: 0;
        text-align: left;
      }
    }
`;
