import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { vwMobile, vwTablet, vw } from 'src/styles/utils';
import media from 'src/styles/media';

const Button = ({ path, label, inverted, param, url, type }) => {
  return (
    <Root>
      {type === 'submit' && <Submit type={type} inverted={inverted} />}
      {path && (
        <StyledLink
          to={{
            pathname: path,
            state: param ? param : undefined,
          }}
          inverted={inverted}
        >
          {label}
        </StyledLink>
      )}
      {url && (
        <StyledA
          href={`${url}`}
          target="_blank"
          rel="noopener noreferrer"
          inverted={inverted}
        >
          {label}
        </StyledA>
      )}
    </Root>
  );
};

export default Button;

const Root = styled.div`
  z-index: 3;
  position: relative;
  a {
    font-weight: ${({ theme }) => theme.fontButton};
    font-size: ${vwMobile(13)};
    font-weight: normal;
    letter-spacing: ${vwMobile(1.5)};
    text-align: center;
    display: inline-block;
    padding: ${vwMobile(20)} ${vwMobile(50)};
  }
  @media (min-width: ${media.xsup}) {
    a {
      font-size: ${vwTablet(13)};
      letter-spacing: ${vwTablet(1.5)};
      padding: ${vwTablet(20)} ${vwTablet(67.5)};
    }
  }
  @media (min-width: ${media.mdup}) {
    a {
      font-size: ${vw(13)};
      font-weight: normal;
      letter-spacing: ${vw(1.5)};
      padding: ${vw(20)} ${vw(72)};
    }
  }
`;

const buttonStyles = css`
  background: ${props => (props.inverted ? 'white' : 'none')};
  border: 1px solid
    ${props => (props.inverted ? props.theme.colorNavy : 'white')};
  border-bottom: 3px solid
    ${props => (props.inverted ? props.theme.colorNavy : 'white')};
  color: ${props => (props.inverted ? props.theme.colorNavy : 'white')};
  transition: all 0.2s;
  &:hover {
    background: ${props => (props.inverted ? props.theme.colorNavy : 'white')};
    color: ${props => (props.inverted ? 'white' : props.theme.colorNavy)};
  }
`;

const StyledLink = styled(Link)`
  ${buttonStyles};
`;

const StyledA = styled.a`
  ${buttonStyles};
  }
`;

const Submit = styled.input`
  height: ${vwMobile(52)};
  width: ${vwMobile(160)};
  font-size: ${vwMobile(13)};
  letter-spacing: ${vwMobile(1.5)};
  ${buttonStyles}
  margin-bottom:${vwMobile(60)};
  cursor: pointer;
  @media(min-width:${media.xsup}){
    height:${vwTablet(52)};
    width:${vwTablet(160)};
    font-size:${vwTablet(13)};
    letter-spacing:${vwTablet(1.5)};
    margin-bottom:${vwTablet(52)};

  }
  @media(min-width:${media.mdup}){
    height:${vw(52)};
    width:${vw(217)};
    font-size:${vw(13)};
    letter-spacing:${vw(1.5)};
margin-bottom:${vw(66)};
  }
`;
