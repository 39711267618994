import React, { Component } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import PropertyCard from 'components/PropertyCard';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

class CardCarousel extends Component {
  state = {
    activeIndex: 0,
  };

  handleChange = currentSlide => {
    this.setState({ activeIndex: currentSlide });
  };
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 100,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: 'center',
      centerMode: true,
      centerPadding: '30px',
    };

    return (
      <Root>
        <div>
          <Slider {...settings} afterChange={this.handleChange}>
            {this.props.properties.map((property, index) => (
              <PropertyCard key={index} property={property} />
            ))}
          </Slider>
        </div>
        <Counter>
          {this.state.activeIndex + 1}/{this.props.properties.length}
        </Counter>
      </Root>
    );
  }
}

export default CardCarousel;

const Root = styled.div`
  padding-top: ${vwMobile(24)};
  padding-bottom: ${vwMobile(42)};
  .slick-track {
    height: ${vwMobile(289)};
  }
  .slick-slide {
    opacity: 0.5;
    transition: opacity 0.5s;
    height: ${vwMobile(289)};
    width: ${vwMobile(260)};
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      height: ${vwMobile(259)};
      transition: all 0.4s;
      padding: 0 ${vwMobile(8)};

      /* padding:0 ${vwMobile(8)}; */

      /* width: ${vwMobile(234)}; */

    }
  }
  .slick-active {
    opacity: 1;
    height: ${vwMobile(289)};

    /* width: ${vwMobile(260)}; */
    /* margin: 0 ${vwMobile(8)}; */

    > div {
      padding: 0 ${vwMobile(8)};

      height: ${vwMobile(289)};
      width: ${vwMobile(268)};

    }
  }
  .slick-dots {
    display: none !important;
  }
  @media (min-width: ${media.xsup}) {
    display: none;
  }
`;

const Counter = styled.span`
  padding-top: ${vwMobile(12)};
  text-align: center;
  display: block;
  font-size: ${vwMobile(14)};
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: normal;
`;
