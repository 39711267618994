import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const formatPath = title => {
  return title.replace(/\s+/g, '-').toLowerCase();
};

const PropertyCard = ({ property }) => {
  const { title, images, status, description } = property;
  return (
    <Root
      to={{
        pathname: `/properties/${property.type}/${formatPath(title)}`,
        state: 'viewingProperty',
      }}
    >
      <Header>{title}</Header>
      <ImageContainer>
        {images !== undefined ? (
          <img src={images[0]} alt={title} />
        ) : (
          <img
            src={require('src/assets/properties/sunwins_placeholder.jpg')}
            alt="Placeholder Image"
          />
        )}
      </ImageContainer>
      <Body>
        <h4>{status}</h4>
        <p>{description}</p>
      </Body>
    </Root>
  );
};

export default PropertyCard;

const Root = styled(Link)`
  /* height: ${vwMobile(289)}; */
  height:100%;
  /* width: ${vwMobile(260)}; */
  width: 100%;
  background: white;
  margin: 0 auto;
  box-sizing: content-box;
  display: block;
  cursor: pointer;
  border-radius: 2px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (min-width: ${media.xsup}) {
    margin: 0;
    height: ${vwTablet(293)};
    width: ${vwTablet(222)};
    margin-bottom: ${vwTablet(21)};
    margin-right: ${vwTablet(13)};
    flex: 0 1 ${vwTablet(222)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(397)};
    margin-right: ${vw(20)};
    margin-bottom: ${vw(27)};
    width: ${vw(392)};
    flex: 0 1 ${vw(392)};
  }
`;

const ImageContainer = styled.div`
  /* height: ${vwMobile(140)};
   */
   height:48.44%;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(120)};
  }
`;

const Header = styled.h3`
  font-size: ${vwMobile(16)};
  font-weight: 500;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNavy};
  /* height: ${vwMobile(52)}; */
  height: 17.99%;
  display: flex;
  flex-direction: row;
  align-items: Center;
  padding-left: ${vwMobile(16)};
  padding-right: ${vwMobile(16)};
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(76)};
    font-size: ${vwTablet(16)};
    align-items: flex-start;
    padding-top: ${vwTablet(12)};
    padding-left: ${vwTablet(16)};
    padding-right: ${vwTablet(16)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(63)};
    font-size: ${vw(18)};
  }
`;

const Body = styled.div`
  padding-top: ${vwMobile(12)};
  padding-left: ${vwMobile(16)};
  padding-right: ${vwMobile(16)};
  padding-bottom: ${vwMobile(16)};
  color: ${({ theme }) => theme.colorCharcoal};

  h4 {
    text-transform: uppercase;
    margin-bottom: ${vwMobile(4)};
  }
  p {
    font-size: ${vwMobile(14)};
    line-height: 1.5;
  }
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(12)};
    padding-left: ${vwTablet(16)};
    padding-right: ${vwTablet(16)};
    padding-bottom: ${vwTablet(16)};
    h4 {
      margin-bottom: ${vwTablet(4)};
      font-size: ${vwTablet(13)};
    }
    p {
      font-size: ${vwTablet(14)};
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-top: ${vw(18)};
    padding-left: ${vw(34)};
    padding-right: ${vw(34)};
    padding-bottom: ${vw(20)};
    h4 {
      font-size: ${vw(13)};
      margin-bottom: ${vw(5)};
    }
    p {
      font-size: ${vw(15)};
    }
  }
`;
