import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

import menu from 'src/data/menu';

const Menu = ({ isOpen, handleMenu, mobileHidden }) => {
  return (
    <Root isOpen={isOpen} mobileHidden={mobileHidden}>
      {menu.map((link, index) => (
        <NavLink
          to={{
            pathname: link.path,
            state: link.param ? link.param : undefined,
          }}
          activeClassName="isActive"
          key={index}
          onClick={() => handleMenu(false)}
        >
          {link.title}
        </NavLink>
      ))}
    </Root>
  );
};

export default Menu;

const Root = styled.div`
  position: fixed;
  top: ${vwMobile(80)};
  left: 0;
  transform: ${props => (props.isOpen ? `translateX(0)` : 'translateX(100%)')};
  height: 100vh;
  width: 100%;
  background: ${({ theme }) => theme.colorNavy};
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: ${vwMobile(60)};
  transition: transform 0.4s ease-out;
  a {
    font-size: ${vwMobile(14)};
    font-weight: 500;
    letter-spacing: ${vwMobile(3.2)};
    opacity: 0.9;
    color: white;
    text-transform: uppercase;
    margin-bottom: ${vwMobile(36)};
    transition: color 0.4s ease-out;
    &:hover {
      color: ${({ theme }) => theme.colorGold};
    }
    &.isActive {
      color: ${({ theme }) => theme.colorGold};
    }
  }
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(120)};
    top: ${vwTablet(80)};
    a {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(3.0)};
    }
  }
  @media (min-width: ${media.mdup}) {
    display: ${props => (props.mobileHidden ? 'none' : 'block')};
    position: static;
    height: auto;
    width: auto;
    padding-top: 0;
    margin-top: 0;
    transform: none;
    padding-bottom: ${vw(54)};
    a {
      font-size: ${vw(12)};
      letter-spacing: ${vw(2.8)};
      margin-left: ${vw(40)};
    }
  }
`;
