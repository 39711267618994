import React from 'react';
import styled from 'styled-components';

import PageLayout from 'components/PageLayout';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
import Footer from 'components/Footer';

const Terms = () => {
  return (
    <PageLayout background="white">
      <TextContainer>
        <h1>Terms of Use</h1>
        <p>
          The material, images, renderings and information on this website is
          provided on an ‘as is’ basis solely for the reader’s general
          knowledge. The information is not intended to be a comprehensive
          review of all matters and developments concerning Sunwins Holdings,
          and therefore assumes no responsibility and provides no warranty,
          either express or implied, as to its accuracy and or completeness. In
          no event will Sunwins Holdings be liable for any special, incidental,
          indirect or consequential damages of any kind, or any damages
          whatsoever arising out of or in connection with the use or performance
          of this information, including without limitation, those resulting
          from loss of use, data or profits, whether or not advised of the
          possibility of damage. This site may contain links to external
          organizations. We do not control the relevance, timeliness, or
          accuracy of materials produced by other organizations, nor do we
          endorse their views, products or services. Your access and use of this
          website constitutes your agreement to be governed by the contents of
          the disclaimer set forth above.
        </p>
      </TextContainer>
      <Footer />
    </PageLayout>
  );
};

export default Terms;

const TextContainer = styled.div`
  padding-top: ${vwMobile(40)};
  padding-left: ${vwMobile(20)};
  padding-right: ${vwMobile(20)};
  padding-bottom: ${vwMobile(100)};
  h1 {
    margin-bottom: ${vwMobile(24)};
  }
  h1,
  p {
    color: ${({ theme }) => theme.colorCharcoal};
  }
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(60)};
    padding-left: ${vwTablet(30)};
    padding-right: ${vwTablet(30)};
    padding-bottom: ${vwTablet(120)};
    h1 {
      margin-bottom: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-left: ${vw(104)};
    padding-right: ${vw(104)};
    padding-top: ${vw(120)};
    padding-bottom: ${vw(100)};
    h1 {
      margin-bottom: ${vw(24)};
    }
    h1,
    p {
      width: ${vw(812)};
    }
  }
`;
