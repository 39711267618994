import React, { Component } from 'react';
import styled from 'styled-components';

import PageLayout from 'components/PageLayout';
import Footer from 'components/Footer';
import Landing from './sections/Landing';
import Projects from './sections/Projects';
import Contact from './sections/Contact';
class Home extends Component {
  render() {
    return (
      <PageLayout>
        <Landing />
        <Projects />
        <Contact />
        <Footer />
      </PageLayout>
    );
  }
}

export default Home;
