import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const QuickLinks = ({ mapLink, url }) => {
  return (
    <Root>
      {/* <Links>
        <h6>Quick Links</h6>
        {url && (
          <a href={url} target="_blank" rel="noopener noreferrer">
            Visit Website
          </a>
        )}
        {mapLink && (
          <a href={mapLink} target="_blank" rel="noopener noreferrer">
            Vew on Map
          </a>
        )}
      </Links> */}
      <Inquiries>
        <h6>General Inquiries</h6>
        <a
          href="mailto:info@sunwins.ca"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@sunwins.ca
        </a>
        <a href="tel:604-284-5366">604.284.5366</a>
        <Link to="/get-in-touch">Registration</Link>
      </Inquiries>
    </Root>
  );
};

export default QuickLinks;

const Root = styled.div`
  background: ${({ theme }) => theme.colorLightGray};
  padding-top: ${vwMobile(40)};
  padding-left: ${vwMobile(20)};
  padding-bottom: ${vwMobile(40)};
  h6 {
    text-transform: uppercase;
    font-size: ${vwMobile(13)};
    line-height: 1.77;
    letter-spacing: ${vwMobile(1.5)};
    color: ${({ theme }) => theme.colorCharcoal};
    margin-bottom: ${vwMobile(16)};
  }
  a {
    font-size: ${vwMobile(15)};
    line-height: 1.6;
    color: ${({ theme }) => theme.colorNavy};
    font-weight: normal;
    font-family: ${({ theme }) => theme.fontButton};
    margin-bottom: ${vwMobile(8)};
  }
  @media (min-width: ${media.xsup}) {
    display: flex;
    flex-direction: row;
    padding-top: ${vwTablet(40)};
    padding-left: ${vwTablet(30)};
    padding-bottom: ${vwTablet(32)};
    h6 {
      font-size: ${vwTablet(13)};
      letter-spacing: ${vwTablet(1.5)};
      margin-bottom: ${vwTablet(16)};
    }
    a {
      font-size: ${vwTablet(15)};
      line-height: 1.6;
      letter-spacing: normal;
      margin-bottom: ${vwTablet(8)};
      transition: all 0.2s;
      &:hover {
        color: ${({ theme }) => theme.colorGold};
      }
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-top: ${vw(40)};
    padding-left: ${vw(104)};
    padding-bottom: ${vw(32)};
    h6 {
      font-size: ${vw(13)};
      letter-spacing: ${vw(1.5)};
      margin-bottom: ${vw(20)};
    }
    a {
      font-size: ${vw(15)};
      line-height: 1.6;
      margin-bottom: ${vw(8)};
    }
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(245)};
  }
`;

const Inquiries = styled.div`
  display: flex;
  flex-direction: column;
`;
