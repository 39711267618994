import React from 'react';
import styled from 'styled-components';
import Reveal from 'react-reveal/Reveal';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
import GoldText from 'components/GoldText';
import Form from '../elements/Form/Form';

const Careers = () => {
  return (
    <Root>
      <img
        src={require('src/assets/properties/river-park-place/MYIE_RiverParkPlace_Project1.jpg')}
        alt="River Park Place"
      />
      <TopContainer>
        <Reveal effect="fadeInUp" delay={200}>
          <h1>Careers</h1>
        </Reveal>
        <Reveal effect="fadeInUp" delay={300}>
          <p>
            With a culture founded on applying the highest standards to the
            naturally spectacular world around us, we are always looking for
            talented, driven people who share our vision to join our team.
          </p>
        </Reveal>
        <Reveal effect="fadeInUp" delay={400}>
          <h1>
            email:{' '}
            <GoldText>
              <a
                href="mailto:careers@sunwins.ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                careers@sunwins.ca
              </a>
            </GoldText>
          </h1>
        </Reveal>
      </TopContainer>
      <BottomContainer>
        <h1>Get in touch</h1>
        <Form />
      </BottomContainer>
    </Root>
  );
};

export default Careers;

const Root = styled.div`
  z-index: 99;
  position: relative;

  img {
    height: ${vwMobile(260)};
    width: ${vwMobile(280)};
    object-fit: cover;
    margin: 0 auto;
    display: block;
    margin-top: ${vwMobile(-60)};
    margin-bottom: ${vwMobile(40)};
  }
  @media (min-width: ${media.xsup}) {
    img {
      height: ${vwTablet(319)};
      width: ${vwTablet(344)};
      margin: 0;
      margin-top: ${vwTablet(-66)};
      margin-left: ${vwTablet(30)};
      display: inline-block;
    }
  }
  @media (min-width: ${media.mdup}) {
    img {
      height: ${vw(496)};
      width: ${vw(524)};
      margin-left: ${vw(104)};
      margin-top: ${vw(-191)};
    }
  }
`;

const TopContainer = styled.div`
  color: ${({ theme }) => theme.colorCharcoal};
  padding-left: ${vwMobile(20)};
  padding-right: ${vwMobile(20)};
  h1,
  p {
    margin-bottom: ${vwMobile(24)};
  }
  a {
    color: ${({ theme }) => theme.colorGold};
  }
  @media (min-width: ${media.xsup}) {
    padding-right: ${vwTablet(30)};
    padding-left: 0;
    display: block;
    margin-top: ${vwTablet(-260)};
    h1,
    p {
      margin-bottom: ${vwTablet(24)};
      margin-left: auto;
      width: ${vwTablet(344)};
    }
    h1:first-of-type {
      margin-top: ${vwTablet(40)};
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-right: ${vw(78)};
    margin-top: ${vw(-305)};
    p {
      margin-top: ${vw(30)};
      margin-bottom: ${vw(35)};
    }
    h1 {
      margin-bottom: 0;
      margin-top: 0;
    }
    h1,
    p {
      width: ${vw(525)};
    }
  }
`;

const BottomContainer = styled.div`
  padding-top: ${vwMobile(20)};
  color: ${({ theme }) => theme.colorCharcoal};
  padding-left: ${vwMobile(20)};
  padding-right: ${vwMobile(20)};
  h1 {
    margin-bottom: ${vwMobile(24)};
  }
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(16)};
    h1 {
      margin-bottom: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-top: ${vw(60)};
    padding-left: ${vw(104)};
    h1 {
      margin-bottom: ${vw(55)};
    }
  }
`;
