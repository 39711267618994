import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
import GoogleMap from '../elements/GoogleMap/GoogleMap';

const Map = () => {
  return (
    <Root>
      <h1>Our Location</h1>
      <MapContainer>
        <GoogleMap />
      </MapContainer>
    </Root>
  );
};

export default Map;

const Root = styled.div`
  h1 {
    margin-bottom: ${vwMobile(24)};
    padding-left: ${vwMobile(20)};
    color: ${({ theme }) => theme.colorCharcoal};
  }
  @media (min-width: ${media.xsup}) {
    h1 {
      margin-bottom: ${vwTablet(24)};
      padding-left: ${vwTablet(30)};
    }
  }
  @media (min-width: ${media.mdup}) {
    h1 {
      margin-bottom: ${vw(21)};
      padding-left: ${vw(104)};
    }
  }
`;

const MapContainer = styled.div`
  width: 100%;
  max-height: ${vwMobile(360)};
  position: relative;

  @media (min-width: ${media.xsup}) {
    max-height: ${vwTablet(360)};
  }
  @media (min-width: ${media.mdup}) {
    max-height: ${vw(495)};
  }
`;
