import React from 'react';
import styled from 'styled-components';
import Reveal from 'react-reveal/Reveal';
import { Parallax } from 'react-scroll-parallax';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
import PolygonText from 'components/PolygonText';
import GoldText from 'components/GoldText';

const Promise = ({ parallaxDisabled }) => {
  return (
    <Root>
      <Parallax y={[10, -10]} disabled={parallaxDisabled}>
        <img
          src={require('src/assets/images/about/about-ourvision2.jpg')}
          alt="People Conversing"
        />
      </Parallax>
      <PromiseText>
        <Reveal effect="fadeInUp" delay={200}>
          <h1>
            Our <GoldText>Promise</GoldText>
          </h1>
        </Reveal>

        <Reveal effect="fadeInUp" delay={300}>
          <p>
            Our promise to our homeowners, our clients, and everyone we do
            business with is to always add real value to the communities we
            develop in.{' '}
          </p>
        </Reveal>
        <Reveal effect="fadeInUp" delay={400}>
          <p>
            By incorporating a spirit of innovation into everything we do, and
            building on the legacy of trust we have established over the last 2
            decades, our projects will always strive to add to the quality of
            life of those who experience them.
          </p>
        </Reveal>
      </PromiseText>
      <PolygonText>
        <MobileText>
          <h1>
            {' '}
            Adding <GoldText>real value</GoldText> to
            <br /> the communities we
            <br /> develop in
          </h1>
        </MobileText>
        <DesktopText>
          <h1>
            Adding <GoldText>real value</GoldText>
          </h1>
          <h1> to the communities</h1>
          <h1> we develop in</h1>
        </DesktopText>
      </PolygonText>
    </Root>
  );
};

export default Promise;

const Root = styled.div`
  padding-left: ${vwMobile(20)};
  padding-right: ${vwMobile(20)};
  margin-top: ${vwMobile(-60)};
  z-index: 10;
  position: relative;
  img {
    object-fit: cover;
    object-position: top;
    height: ${vwMobile(260)};
    width: 100%;
    margin-bottom: ${vwMobile(40)};
  }
  h1 {
    margin-bottom: ${vwMobile(24)};
    color: ${({ theme }) => theme.colorCharcoal};
  }
  p {
    color: ${({ theme }) => theme.colorCharcoal};

    &:last-of-type {
      margin-top: ${vwMobile(24)};
      margin-bottom: ${vwMobile(40)};
      font-size: ${vwMobile(14)};
    }
  }
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(-270)};
    padding-left: ${vwTablet(30)};
    padding-right: ${vwTablet(50)};
    img {
      height: ${vwTablet(319)};
      width: ${vwTablet(344)};
      margin-bottom: ${vwTablet(40)};
    }
    h1 {
      margin-bottom: ${vwTablet(24)};
    }
    p:last-of-type {
      font-size: ${vwTablet(14)};
      line-height: 1.5;
      margin-top: ${vwTablet(24)};
      margin-bottom: 0;
      padding-bottom: ${vwTablet(76)};
    }
  }
  @media (min-width: ${media.mdup}) {
    margin-top: ${vw(-248)};
    padding-left: ${vw(104)};
    padding-right: ${vw(159)};
    img {
      height: ${vw(496)};
      width: ${vw(524)};
      margin-bottom: ${vw(44)};
    }
    h1 {
      margin-bottom: ${vw(30)};
    }
    p {
      &:last-of-type {
        font-size: ${vw(15)};
        line-height: 1.6;
        margin-top: ${vw(18)};
        margin-bottom: 0;
        padding-bottom: ${vw(88)};
      }
    }
  }
`;

const PromiseText = styled.div`
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(344)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(593)};
  }
`;

const MobileText = styled.div`
  @media (min-width: ${media.mdup}) {
    display: none;
  }
`;

const DesktopText = styled.div`
  display: none;
  background: white;

  @media (min-width: ${media.mdup}) {
    display: block;
    padding-top: ${vw(38)};
    padding-bottom: ${vw(38)};
    padding-left: ${vw(54)};
    padding-right: ${vw(54)};
    width: ${vw(575)};
    h1 {
      padding: 0;
      margin-bottom: ${vw(17)};
      text-align: right;
      &:first-of-type {
        padding-top: 0;
        text-align: center;
      }
      &:last-of-type {
        padding-bottom: 0;
        text-align: left;
      }
    }
  }
`;
