import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Parallax, withController } from 'react-scroll-parallax';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

class MaskedLogo extends PureComponent {
  static propTypes = {
    parallaxController: PropTypes.object,
  };

  handleLoad = () => {
    this.props.parallaxController.update();
  };

  render() {
    return (
      <Root>
        <StaticImageContainer>
          <Parallax y={[-40, 0]}>
            <StaticImage
              background={require('src/assets/images/home/homepage-hero.jpg')}
              onLoad={this.handleLoad}
            />
          </Parallax>
        </StaticImageContainer>

        <MaskLayer mask={require('src/assets/images/home/logo-mask-v3.svg')} />
      </Root>
    );
  }
}

const Root = styled.div`
  height: ${vwMobile(440)};
  width: 100%;
  overflow: hidden;
  position: relative;
  @media (min-width: ${media.xsup}) {
    height: 100%;
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(616)};
    margin-left: ${vw(-2)};
  }
`;

const StaticImage = styled.div`
  /* background-image: ${props =>
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)), linear-gradient(to bottom, #f7f7f7, #f7f7f7), url(${
      props.background
    })`}; */
  /* background-blend-mode: multiply; */
  background-image:${props => `url(${props.background})`};
  background-size:Cover;
  background-position:center;
  background-repeat: no-repeat;
  /* background-size: cover, cover;
  background-position: center, center;
  background-repeat: no-repeat, no-repeat; */
  height: 100%;
  width: 100%;
`;

const StaticImageContainer = styled.div`
  position: absolute;
  /* top: ${vwMobile(320)}; */
  top:${vwMobile(120)};
  left: 0;
  min-width: 100%;
  height: ${vwMobile(350)};
  z-index: -1;
  > div {
    height: 100%;
    width: 100%;
    > div {
      height: 100%;
      width: 100%;
    }
  }
  @media(min-width:${media.xsup}){
    height:${vwTablet(500)};
    top: ${vwTablet(50)};
  }
  @media (min-width: ${media.mdup}) {
    min-height: 90%;
    /* height: 90vh; */
    height: ${vw(660)};
    top: auto;
    bottom: ${vw(-120)};
  }
`;

const MaskLayer = styled.div`
  background: ${({ theme }) => theme.colorNavy};
  mask-image: ${props => `url(${props.mask})`};
  mask-size: cover;
  mask-repeat: no-repeat;
  mask-position: center;
  position: relative;
  height: 100%;
  width: 100%;
  @media (min-width: ${media.mdup}) {
    width: ${vw(616)};
    padding-top: ${vw(20)};
  }
`;

export default withController(MaskedLogo);
