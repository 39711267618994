import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
import ContactBanner from 'components/ContactBanner';
import GoldText from '../../../components/GoldText';

const Contact = () => {
  return (
    <Root>
      <Title>
        Get in <GoldText>touch</GoldText>
      </Title>
      <ContactBanner />
    </Root>
  );
};

export default Contact;

const Root = styled.div`
  background: ${({ theme }) => theme.colorNavy};
  position: relative;
`;

const Title = styled.h1`
  padding-left: ${vwMobile(20)};
  padding-right: ${vwMobile(20)};
  padding-bottom: ${vwMobile(16)};
  @media (min-width: ${media.xsup}) {
    padding-bottom: ${vwTablet(16)};
    padding-left: ${vwTablet(30)};
  }
  @media (min-width: ${media.mdup}) {
    padding-left: ${vw(104)};
    padding-bottom: ${vw(22)};
  }
`;
