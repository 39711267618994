import React, { Component } from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import mapStyles from './mapStyles';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
// const AnyReactComponent = () => (
//   <Pin background={require('src/assets/images/icons/pin.png')} />
// );

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 49.1923292,
      lng: -123.1332888,
    },
    zoom: 14,
  };

  // renderMarkers(map, maps) {
  //   const icon = `${require('src/assets/images/icons/pin.png')}`;

  //   return new maps.Marker({
  //     position: { lat: 49.1780294, lng: -123.1379569 },
  //     map: map,
  //     icon: icon,
  //   });
  // }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '480px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDA0uXlfqQlmwraObnBYCp1hz4BGs79gFA' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={{ styles: mapStyles }}
        >
          <Pin
            lat={49.1923292}
            lng={-123.1332888}
            background={require('src/assets/images/icons/pin.png')}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMap;

const Pin = styled.div`
  background: ${props => `url(${props.background})`};
  background-size: contain;
  position: absolute;

  left: 100%;
  top: 100%;
  transform: translate(50%, -100%);
  height: ${vwMobile(40)};
  width: ${vwMobile(28)};
  background-repeat: no-repeat;

  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(40)};
    width: ${vwTablet(28)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(40)};
    width: ${vw(28)};
  }
`;
