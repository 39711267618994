import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Headroom from 'react-headroom';

import Menu from './elements/Menu';
import Icon from './elements/Icon';

import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

export default class Header extends Component {
  state = {
    isMobileMenuOpen: false,
  };

  handleMenu = () => {
    this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen });
  };

  render() {
    return (
      <Root
        disableInlineStyles
        menuopen={this.state.isMobileMenuOpen ? 'true' : 'false'}
      >
        <Wrapper menuOpen={this.state.isMobileMenuOpen}>
          <Link to="/">
            <SunwinsLogo
              src={require('src/assets/images/logos/sunwins-logo-header.png')}
              alt="Sunwins Logo"
            />
          </Link>
          <Icon
            onClick={() => this.handleMenu(!this.state.isMobileMenuOpen)}
            isOpen={this.state.isMobileMenuOpen}
          />
          <Menu
            handleMenu={this.handleMenu}
            isOpen={this.state.isMobileMenuOpen}
          />
        </Wrapper>
      </Root>
    );
  }
}

const Root = styled(Headroom)`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  overflow: hidden;
  margin-bottom: -1px;
  position: unset;
  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 999999;
    transition: transform 0.4s;
    position: fixed;
  }
  .headroom--unfixed {
    transform: translateY(0);
    transform: ${props => props.menuopen === 'true' && 'none'};
    transition: transform 0.4s;
  }
  .headroom--scrolled {
  }
  .headroom--unpinned {
    transform: ${props =>
      props.menuopen === 'true' ? 'none' : 'translateY(-100%)'};
    transition: transform 0.4s;
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
    transition: transform 0.4s;
  }
`;

const Wrapper = styled.header`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${vwMobile(80)};
  padding: ${vwMobile(0)} ${vwMobile(20)};
  z-index: 100;
  text-align: center;
  background: ${({ theme }) => theme.colorNavy};
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(80)};
    padding: ${vwTablet(0)} ${vwTablet(20)};
  }
  @media (min-width: ${media.mdup}) {
    align-items: flex-end;
    position: relative;
    padding: 0 ${vw(77)};
    height: ${vw(150)};
  }
`;

const SunwinsLogo = styled.img`
  height: ${vwMobile(48)};
  width: ${vwMobile(74.5)};
  cursor: pointer;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(48)};
    width: ${vwTablet(74.5)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(85)};
    width: ${vw(132)};
    margin-bottom: ${vw(33)};
  }
`;
