import React from 'react';
import styled from 'styled-components';
import Reveal from 'react-reveal/Reveal';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
import GoldText from 'components/GoldText';
import PolygonText from 'components/PolygonText';

const ContactInfo = () => {
  return (
    <Root>
      <LeftColumn>
        <Reveal effect="fadeInUp" delay={200}>
          <h1>
            Contact <GoldText>Us</GoldText>
          </h1>
        </Reveal>
        <Reveal effect="fadeInUp" delay={300}>
          <h6>Sunwins Holdings</h6>
        </Reveal>
        <a
          href="https://www.google.com/maps/place/8400+W+Rd,+Richmond,+BC/@49.1923292,-123.1332888,17z/data=!3m1!4b1!4m5!3m4!1s0x548674de56e0ce37:0x2a899fde588fc436!8m2!3d49.1923292!4d-123.1311001"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          <Reveal effect="fadeInUp" delay={400}>
            <h1>
              #1205 - 8400 West Road
              <br />
              Richmond BC
              <br />
              V6X 0S7
            </h1>
          </Reveal>
        </a>
      </LeftColumn>
      <RightColumn>
        <PolygonText
          textBackground={({ theme }) => theme.colorNavy}
          textAlign="left"
        >
          <MobileText>
            <h1>
              <a href="tel:604-284-5366">
                tel: <GoldText>604 284 5366</GoldText>
              </a>
              <br />

              <a href="fax:604-274-9802">
                fax: <GoldText>604 274 9802</GoldText>
              </a>
              <br />

              <a
                href="mailto:info@sunwins.ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                email: <GoldText>info@sunwins.ca</GoldText>
              </a>
              <br />
            </h1>
          </MobileText>
          <DesktopText>
            <h1>
              <a href="tel:604-284-5366">
                tel: <GoldText>604 284 5366</GoldText>
              </a>
            </h1>
            <h1>
              <a href="fax:604-274-9802">
                fax: <GoldText>604 274 9802</GoldText>
              </a>
            </h1>
            <h1>
              <a
                href="mailto:info@sunwins.ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                email: <GoldText>info@sunwins.ca</GoldText>
              </a>
            </h1>
          </DesktopText>
        </PolygonText>
      </RightColumn>
    </Root>
  );
};

export default ContactInfo;

const Root = styled.div`
  background: ${({ theme }) => theme.colorNavy};
  padding-top: ${vwMobile(40)};
  padding-bottom: ${vwMobile(120.7)};
  position: relative;
  @media (min-width: ${media.xsup}) {
    display: flex;
    flex-direction: row;
    padding-top: ${vwTablet(0)};
    padding-bottom: ${vwTablet(40)};
    height: ${vwTablet(457)};
  }
  @media (min-width: ${media.mdup}) {
    padding-bottom: ${vwTablet(55)};
    height: ${vw(681)};
  }
`;

const LeftColumn = styled.div`
  padding-left: ${vwMobile(20)};

  h1 {
    margin-bottom: ${vwMobile(40)};
  }
  h6 {
    font-size: ${vwMobile(13)};
    line-height: 1.77;
    letter-spacing: ${vwMobile(1.5)};
    text-transform: uppercase;
    margin-bottom: ${vwMobile(12)};
  }
  a h1 {
    color: white;
    line-height: 1.21;
  }
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(60)};
    h1 {
      margin-bottom: ${vwTablet(40)};
    }
    h6 {
      font-size: ${vwTablet(13)};
      letter-spacing: ${vwTablet(1.5)};
      margin-bottom: ${vwTablet(12)};
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-top: ${vw(151)};
    h1 {
      margin-bottom: ${vw(66)};
    }
    h6 {
      font-size: ${vw(13)};
      letter-spacing: ${vw(1.5)};
      margin-bottom: ${vw(13)};
    }
    a h1 {
      line-height: 1.4;
    }
  }
`;

const RightColumn = styled.div`
  padding-left: ${vwMobile(20)};
  padding-right: ${vwMobile(20)};
  a {
    color: white;
  }
  @media (min-width: ${media.xsup}) {
    padding-right: ${vwTablet(50)};
    h1 {
      width: ${vwTablet(304)};
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-right: ${vw(78)};
  }
`;

const MobileText = styled.div`
  @media (min-width: ${media.mdup}) {
    display: none;
  }
`;

const DesktopText = styled.div`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    margin: 0 auto;
    background: ${({ theme }) => theme.colorNavy};
    padding-top: ${vw(38)};
    padding-bottom: ${vw(37)};
    h1 {
      width: auto;
    }
  }
`;
