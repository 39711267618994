import React from 'react';

export default {
  clubVersante: {
    title: 'Club Versante',
    status: 'COMING SOON',
    description: 'A revolutionary dining and style club',
    extendedDescription: `Club Versante will be a private dining experience where members can savour in the sublime menus of three restaurants and bars, enjoy the services of a member’s only lifestyle concierge, as well as host private events and meetings. Along with these exclusive benefits, members will also enjoy privileged access to the adjoining Versante Hotel and its rooftop restaurant.`,
    overviewType: 'Private Club',
    images: [
      require('src/assets/properties/club-versante/club-versante-box.jpg'),
      require('src/assets/properties/club-versante/club-versante-1.jpg'),

      require('src/assets/properties/club-versante/club-versante-2.jpg'),
    ],
    type: 'hospitality',
    link: 'club-versante',
    url: 'https://clubversante.com/',
    location: (
      <>
        8400 West Road, <br />
        Richmond, B.C.
      </>
    ),
    locationLink:
      'https://www.google.ca/maps/place/8400+W+Rd,+Richmond,+BC/@49.1923292,-123.1332888,17z/data=!3m1!4b1!4m5!3m4!1s0x548674de56e0ce37:0x2a899fde588fc436!8m2!3d49.1923292!4d-123.1311001',
  },
  versante: {
    title: 'Versante',
    status: 'Sold Out',
    description: 'Contemporary residences in the heart of Richmond',
    extendedDescription: `Completed in 2008, Versante has two modern condo towers located in the heart of Richmond’s city centre at 8280-8288 Lansdowne Road. The two towers offer studios and one to four-bedroom suites with ranging from 410 sq. ft. to 1,800 sq. ft. in size.`,
    type: 'residential',
    overviewType: 'Condominiums',
    images: [
      require('src/assets/properties/versante/residential-versante-rendering.jpg'),
    ],
    link: 'versante',
    location: (
      <>
        8288 Lansdowne Road,
        <br />
        Richmond, B.C.
      </>
    ),
    locationLink:
      'https://www.google.ca/maps/place/8288+Lansdowne+Rd,+Richmond,+BC+V6X+3M6/@49.173645,-123.1347209,17z/data=!3m1!4b1!4m5!3m4!1s0x54867533d76ed623:0x122df2d498fdc93e!8m2!3d49.173645!4d-123.1325321',
  },

  internationalTradeCenter: {
    title: 'International Trade Centre',
    status: 'Sold Out',
    description: `Richmond's next business epicentre`,
    extendedDescription: `The International Trade Center features Class ‘A’ office space in one of two towers next to high- end retail space, a private club and the Versante Hotel. Combined with a location that gives you effortless access to Vancouver International Airport, downtown Vancouver as well as rapid transit, this is destined to be Richmond’s next epicentre of international business.`,
    overviewType: 'Mixed Commercial',
    images: [
      require('src/assets/properties/international-trade-center/MYIE_ITC_Project1.jpg'),
    ],
    type: 'commercial',
    link: 'international-trade-centre',
    url: 'http://itcrichmond.com/',
    location: (
      <>
        8451 Bridgeport Road, <br />
        Richmond, B.C.
      </>
    ),
    locationLink:
      'https://www.google.ca/maps/place/8451+Bridgeport+Rd,+Richmond,+BC+V6X+3C7/@49.192382,-123.1342647,17z/data=!3m1!4b1!4m5!3m4!1s0x548674def71f758b:0x410efc0f75c0dd97!8m2!3d49.192382!4d-123.132076',
  },
  richmondOval: {
    title: 'Richmond Oval Village',
    status: 'Sold Out',
    description:
      'Contemporary retail and commercial spaces in the heart of Richmond',
    extendedDescription: `Cultural, connected and convenient, Richmond Oval Village is the rising star of Richmond’s growing riverfront community. A renowned 2010 Olympic Games legacy destination, The Oval Village is a prestigious location for companies who want to be in the heart of an energetic, fast-growing community.`,
    overviewType: 'Mixed Commercial',
    images: [
      require('src/assets/properties/richmond-oval-village/MYIE_OvalVillage_Project1.jpg'),
      require('src/assets/properties/richmond-oval-village/MYIE_OvalVillage_Project2.jpg'),
    ],
    type: 'commercial',
    link: 'richmond-oval-village',
    url: 'http://www.richmondovalvillage.ca',
    location: 'Richmond, B.C.',
  },
  riverParkPlace: {
    title: 'River Park Place',
    status: 'Sold Out',
    description: 'Luxury living at the Richmond Oval',
    extendedDescription: `River Park Place offers a rare, sophisticated lifestyle in Richmond’s emerging Oval Village. Representing a benchmark in luxury – River Park Place puts everything you want from a home in perfect balance.`,
    overviewType: 'Condominiums & Townhomes',
    type: 'residential',
    images: [
      require('src/assets/properties/river-park-place/MYIE_RiverParkPlace_Project1.jpg'),
      require('src/assets/properties/river-park-place/MYIE_RiverParkPlace_Project2.jpg'),
      require('src/assets/properties/river-park-place/MYIE_RiverParkPlace_Project3.jpg'),
    ],
    link: 'river-park-place',
    location: (
      <>
        6900 Pearson Way,
        <br />
        Richmond, B.C.
      </>
    ),
    locationLink:
      'https://www.google.ca/maps/place/6900+Pearson+Way,+Richmond,+BC+V7C+0B3/@49.1755615,-123.1482267,17z/data=!3m1!4b1!4m5!3m4!1s0x54860ad2d860fea5:0x88225441d25885fa!8m2!3d49.1755615!4d-123.146038',
  },
  collection45: {
    title: 'Collection 45',
    status: 'Sold Out',
    description: 'Innovative.  Thoughtful.  Unique',
    extendedDescription: `A boutique collection of only 45 residences, Collection 45 is an artistic vision and aesthetic mindfulness taken to the highest of standards. Design, functionality and masterful construction culminate in a sophisticated living experience in one of Vancouver’s most beloved neighbourhoods.`,
    overviewType: 'Boutique Condominiums',
    type: 'residential',
    images: [
      require('src/assets/properties/collection-45/MYIE_Collection45_1.jpg'),
      require('src/assets/properties/collection-45/MYIE_Collection45_2.jpg'),
      require('src/assets/properties/collection-45/MYIE_Collection45_3.jpg'),
      require('src/assets/properties/collection-45/MYIE_Collection45_4.jpg'),
      require('src/assets/properties/collection-45/MYIE_Collection45_5.jpg'),
    ],
    link: 'collection-45',
    location: (
      <>
        133 East 8th Avenue, <br />
        Vancouver, B.C.
      </>
    ),
    locationLink:
      'https://www.google.ca/maps/place/133+E+8th+Ave,+Vancouver,+BC+V5T+1R8/@49.2638341,-123.1042759,17z/data=!3m1!4b1!4m5!3m4!1s0x548673e0331f3aaf:0x61f2772c4d031636!8m2!3d49.2638341!4d-123.1020872',
  },
  lustre: {
    title: 'Lustre',
    status: 'Sold Out',
    overviewType: 'Townhome',
    description: 'A lavish expression of west coast living',
    extendedDescription: `From the large open floorplans filled with natural light, to the abundance of luxurious natural materials and features throughout every home, Lustre represents one of the finest examples of sophisticated west coast living.`,
    type: 'residential',
    link: 'lustre',
    location: (
      <>
        5600 Blundell Road, <br />
        Richmond, B.C.
      </>
    ),
    locationLink:
      'https://www.google.ca/maps/place/5600+Blundell+Rd,+Richmond,+BC+V7C+1H5/@49.155343,-123.1633317,17z/data=!3m1!4b1!4m5!3m4!1s0x54860a953c343bcb:0x11e2d10f62afd99d!8m2!3d49.155343!4d-123.161143',
    images: [require('src/assets/properties/lustre/lustre-stock.jpg')],
  },
  bellaVista: {
    title: 'Bella Vista',
    status: 'Sold Out',
    overviewType: 'Townhome',
    description: 'Contemporary urban townhomes',
    extendedDescription: `Perfectly positioned in the emerging South McLeanne neighbourhood, Bella Vista’s intimate collection of townhomes offers the ultimate in urban community living. Just steps away from Richmond Town Centre, the distinct West Coast contemporary design and style was created to reflect the dynamic lifestyles of today’s contemporary families.`,
    type: 'residential',
    images: [
      require('src/assets/properties/bella-vista/bella-vista-stock.jpg'),
    ],
    link: 'bella-vista',
    location: (
      <>
        9440 Ferndale Road, <br />
        Richmond, B.C.
      </>
    ),
    locationLink:
      'https://www.google.ca/maps/place/9440+Ferndale+Rd,+Richmond,+BC+V6Y+0A7/@49.167857,-123.1213067,17z/data=!3m1!4b1!4m5!3m4!1s0x5486754852e134b5:0x324873622d41e563!8m2!3d49.167857!4d-123.119118',
  },
  brillia: {
    title: 'Brillia',
    status: 'Sold Out',
    description: 'Contemporary elegance in West Point Gray',
    extendedDescription: `A collection of only 30 exclusive apartments, Brillia is quietly nestled in the prestigious community of West Point Grey. With the beautiful Jericho Beach and English Bay right on its doorstep, Brillia’s stunning mountain and ocean views make every day a breath of fresh air. Here, the best of traditional and contemporary influences merge to create homes of lasting value.`,
    type: 'residential',
    overviewType: 'Townhome',
    images: [require('src/assets/properties/brillia/brillia-stock.jpg')],
    link: 'brillia',
    location: (
      <>
        3839 4th Avenue, <br />
        Vancouver, B.C.
      </>
    ),
    locationLink:
      'https://www.google.ca/maps/place/3839+W+4th+Ave,+Vancouver,+BC+V6R+1P8/@49.2688646,-123.1911447,17z/data=!3m1!4b1!4m5!3m4!1s0x548672f8905886a9:0xb9e3096ffb93ea28!8m2!3d49.2688646!4d-123.1889559',
  },
  acreLavande: {
    title: 'Acre Lavande',
    status: 'Operational',
    description: 'Harvested and handcrafted on Cortes island',
    extendedDescription: `Acre Lavande is an organic farm and boutique lavender handcrafter tucked away on the sunny southern tip of Cortes Island. Made from pure English lavender, Acre Lavande’s products include essential oil, lavender water, soap, lavender hydrosol and eye masks.`,
    overviewType: 'Organic Farm',
    images: [
      require('src/assets/properties/acre-lavande/acre-lavande-1.jpg'),
      require('src/assets/properties/acre-lavande/acre-lavande-2.jpg'),
      require('src/assets/properties/acre-lavande/acre-lavande-3.jpg'),
      require('src/assets/properties/acre-lavande/acre-lavande-4.jpg'),
    ],
    type: 'commercial',
    link: 'acre-lavande',
    url: 'http://acrelavande.com/',
    location: 'Cortes Island',
  },
  versanteHotel: {
    title: 'Versante Hotel',
    status: 'Coming Soon',
    description: 'A five-star boutique hotel in the heart of Richmond',
    extendedDescription: `Versante Hotel is heaven for those seeking a boutique hotel experience in Richmond. Situated within minutes of Vancouver International Airport and serving Canada’s foremost "suburban city", there is everything here for the contemporary international traveller who appreciates life’s pleasures.`,
    overviewType: 'Boutique Hotel',
    type: 'hospitality',
    images: [
      require('src/assets/properties/opus-versante-hotel/lobby3.jpg'),

      require('src/assets/properties/opus-versante-hotel/MYIE_HotelVersante_Project-1140x630.jpg'),
      require('src/assets/properties/opus-versante-hotel/lobby1.jpg'),
      require('src/assets/properties/opus-versante-hotel/lobby2.jpg'),
      require('src/assets/properties/opus-versante-hotel/lobby4.jpg'),
      require('src/assets/properties/opus-versante-hotel/room1.jpg'),

      require('src/assets/properties/opus-versante-hotel/room2.jpg'),

      require('src/assets/properties/opus-versante-hotel/room3.jpg'),
    ],
    link: 'versante-hotel',
    location: (
      <>
        8499 Bridgeport Road, <br />
        Richmond, B.C.
      </>
    ),
    locationLink:
      'https://www.google.ca/maps/place/8499+Bridgeport+Rd,+Richmond,+BC/@49.1921616,-123.1330897,17z/data=!3m1!4b1!4m5!3m4!1s0x548674de556e5a65:0x71dd2b70d921fb43!8m2!3d49.1921616!4d-123.130901',
    url: 'http://versanteHotel.com',
  },
  spaVersante: {
    title: 'Spa Versante',
    status: 'Operational',
    description: 'Wellness for your body, mind & soul',
    extendedDescription: `This luxurious haven is the perfect setting for you to rejuvenate and re-energize. A relaxing sanctuary with 14 lavish treatment rooms for the most discerning guests, Spa Versante is the ultimate retreat where you are encouraged to unwind and more importantly, indulge.`,
    overviewType: 'Wellness Spa',
    type: 'commercial',
    images: [
      require('src/assets/properties/spa-versante/spa-versante.jpg'),

      require('src/assets/properties/spa-versante/spa-versante-medispa1.jpg'),
      require('src/assets/properties/spa-versante/spa-versante-medispa2.jpg'),
      require('src/assets/properties/spa-versante/spa-versante-medispa3.jpg'),
      require('src/assets/properties/spa-versante/spa-versante-medispa4.jpg'),
      require('src/assets/properties/spa-versante/spa-versante-medispa5.jpg'),
    ],
    link: 'spa-versante',
    url: 'https://www.spaversante.com/',
    backgroundAlignment: 'top center',
    location: (
      <>
        8280 Lansdowne Road, <br />
        Richmond, B.C.
      </>
    ),
    locationLink:
      'https://www.google.ca/maps/place/8280+Lansdowne+Rd,+Richmond,+BC+V6X+0B2/@49.1735723,-123.1348226,17z/data=!3m1!4b1!4m5!3m4!1s0x54867533da752bd7:0x649316021c751fa5!8m2!3d49.1735723!4d-123.1326339',
  },
  cowichanBay: {
    title: 'Cowichan Bay',
    status: 'Operational',
    description: 'Discover a home away from home on the waterfront.',
    extendedDescription: `A West Coast destination to return to year after year. Idyllically nestled between Victoria and Nanaimo in the heart of Cowichan Valley, here ocean views, whale watching and vineyard experiences are part your everyday.`,
    overviewType: 'Oceanfront Hotel',
    type: 'hospitality',
    images: [
      require('src/assets/properties/cochiwan-bay/MYIE_Cochiwan_Project1.jpg'),
      require('src/assets/properties/cochiwan-bay/MYIE_Cochiwan_Project2.jpg'),
      require('src/assets/properties/cochiwan-bay/MYIE_Cochiwan_Project3.jpg'),
      require('src/assets/properties/cochiwan-bay/MYIE_Cochiwan_Project4.jpg'),
    ],
    link: 'cowichan-bay',
    url: 'http://oceanfrontcowichanbay.com/',
    location: (
      <>
        1681 Cowichan Bay Road, <br />
        Cowichan Bay, B.C.
      </>
    ),
    locationLink:
      'https://www.google.ca/maps/place/1681+Cowichan+Bay+Rd,+Cowichan+Bay,+BC+V0R+1N0/@48.7402223,-123.6195218,17z/data=!3m1!4b1!4m5!3m4!1s0x548f445c192e1b5f:0x99b76c0b8d7e29cd!8m2!3d48.7402223!4d-123.6173331',
  },
  hotelAcre: {
    title: 'Hotel Acre',
    status: 'Coming Soon',
    description:
      'Luxurious urban hospitality that will make you feel truly special.',
    overviewType: 'Boutique Hotel',

    type: 'hospitality',
    images: [require('src/assets/properties/hotel-acre/hotel-acre.jpg')],
    // TODO: ?? Should this one have a period?

    link: 'hotel-acre',
    location: (
      <>
        300 - 8411 Bridgeport Road, <br />
        Richmond, B.C.
      </>
    ),
    locationLink:
      'https://www.google.ca/maps/place/8411+Bridgeport+Rd,+Richmond,+BC/@49.1923818,-123.1342647,17z/data=!3m1!4b1!4m5!3m4!1s0x548674defba6f35d:0x78a888afc860f47e!8m2!3d49.1923818!4d-123.132076',
    url: 'http://hotelacre.com',
  },
  yvrBeauty: {
    title: 'Vogue Lounge',
    status: 'Coming Soon',
    overviewType: 'Beauty Salon',
    description: 'One stop shop for your ultimate glamour experience.',
    type: 'commercial',
    images: [
      require('src/assets/properties/yvr-beauty/yvrbeauty-1.JPG'),
      require('src/assets/properties/yvr-beauty/yvrbeauty-2.JPG'),
      require('src/assets/properties/yvr-beauty/yvrbeauty-3.JPG'),
      require('src/assets/properties/yvr-beauty/yvrbeauty-4.JPG'),
    ],
    link: 'vogue-lounge',
    location: (
      <>
        230 - 8411 Bridgeport Road, <br />
        Richmond, B.C.
      </>
    ),
    locationLink:
      'https://www.google.ca/maps/place/8411+Bridgeport+Rd,+Richmond,+BC/@49.1923818,-123.1342647,17z/data=!3m1!4b1!4m5!3m4!1s0x548674defba6f35d:0x78a888afc860f47e!8m2!3d49.1923818!4d-123.132076',
  },
};
