import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const Overview = ({ type, status }) => {
  return (
    <Root>
      <h1>Overview</h1>
      <InfoContainer>
        <Type>
          <h6>Type</h6>
          <p>{type}</p>
        </Type>
        <Status>
          <h6>Status</h6>
          <p>{status}</p>
        </Status>
      </InfoContainer>
    </Root>
  );
};

export default Overview;

const Root = styled.div`
  background: ${({ theme }) => theme.colorNavy};
  padding-top: ${vwMobile(40)};
  padding-left: ${vwMobile(20)};
  padding-bottom: ${vwMobile(20)};
  h1 {
    margin-bottom: ${vwMobile(24)};
  }
  h6 {
    font-size: ${vwMobile(13)};
    letter-spacing: ${vwMobile(1.5)};
    line-height: 1.77;
    font-weight: bold;
    text-transform: uppercase;
  }
  p {
    line-height: 1.5;
    font-weight: 300;
    margin-bottom: ${vwMobile(24)};
  }
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(40)};
    padding-left: ${vwTablet(30)};
    padding-bottom: ${vwTablet(40)};
    h1 {
      margin-bottom: ${vwTablet(24)};
    }
    h6 {
      font-size: ${vwTablet(13)};
      letter-spacing: ${vwTablet(1.5)};
    }
    p {
      margin-bottom: 0;
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-top: ${vw(40)};
    padding-left: ${vw(104)};
    padding-bottom: ${vw(46)};
    h1 {
      margin-bottom: ${vw(30)};
    }
    h6 {
      font-size: ${vw(13)};
      letter-spacing: ${vw(1.5)};
    }
  }
`;

const Type = styled.div`
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(245)};
  }
`;

const Status = styled.div``;

const InfoContainer = styled.div`
  @media (min-width: ${media.xsup}) {
    display: flex;
    flex-direction: row;
  }
`;
