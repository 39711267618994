import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const NavigationBar = ({ category, title }) => {
  return (
    <Root>
      <Link to={`/properties/${category}`}>
        <img src={require('src/assets/images/icons/arrow-icon.svg')} />
        <p>
          Back to {category.charAt(0).toUpperCase() + category.slice(1)}{' '}
          Properties
        </p>
        <p>{category.charAt(0).toUpperCase() + category.slice(1)} Properties</p>
      </Link>
      <Arrow src={require('src/assets/images/icons/arrow-icon.svg')} />
      <Title>{title}</Title>
    </Root>
  );
};

export default NavigationBar;

const Root = styled.div`
  background: white;
  z-index: 99;
  position: relative;
  height: ${vwMobile(55)};
  display: flex;
  flex-direction: row;
  align-items: Center;

  a {
    font-size: ${vwMobile(13)};
    padding-left: ${vwMobile(20)};
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  p {
    font-size: ${vwMobile(13)};
    padding-left: ${vwMobile(8)};
    color: ${({ theme }) => theme.colorNavy};
    font-family: ${({ theme }) => theme.fontButton};
    &:nth-of-type(2) {
      display: none;
    }
  }
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(63)};
    a {
      padding: 0 ${vwTablet(30)};
      font-size: ${vwTablet(13)};
      letter-spacing: ${vwTablet(1)};
    }
    p {
      padding-left: ${vwTablet(8)};
      font-size: ${vwTablet(13)};
    }
    img {
      height: ${vwTablet(15)};
      width: ${vwTablet(8)};
    }
  }
  @media (min-width: ${media.mdup}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${vw(36)};
    a {
      font-size: ${vw(13)};
      letter-spacing: ${vw(1)};
      padding: 0 ${vw(77)};
      padding-right: 0;
      img {
        display: none;
      }
      p {
        display: none;
        &:nth-of-type(2) {
          display: block;
        }
      }
    }
    p {
      padding-left: 0;
      padding-right: ${vw(14)};
      font-size: ${vw(13)};
    }
    img {
      height: ${vw(15)};
      width: ${vw(8)};
    }
  }
`;

const Arrow = styled.img`
  display: none;

  @media (min-width: ${media.mdup}) {
    display: block;
    transform: scaleX(-1);
    margin-left: ${vw(10)};
    margin-right: ${vw(14)};

    object-fit: contain;
  }
`;

const Title = styled.p`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
  }
`;
