import React from 'react';
import styled from 'styled-components';

import CardCarousel from 'components/CardCarousel';
import CardGrid from 'components/CardGrid';

import properties from 'src/data/properties';

//TODO: is this component redundant?

const determineProperties = activeCategory => {
  if (activeCategory === 'all') {
    return Object.entries(properties).map(([index, property]) => property);
  } else {
    return Object.entries(properties)
      .filter(([index, property]) => property.type === activeCategory)
      .map(([index, property]) => property);
  }
};

const CardContainer = ({ activeCategory }) => {
  return (
    <Root>
      <CardCarousel
        activeCategory={activeCategory}
        properties={determineProperties(activeCategory)}
      />
      <CardGrid properties={determineProperties(activeCategory)} />
    </Root>
  );
};
export default CardContainer;

const Root = styled.div`
  background: ${({ theme }) => theme.colorNavy};
`;
