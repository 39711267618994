import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import Intro from './sections/Intro';
import CategorySelector from './elements/CategorySelector';
import Banner from './elements/Banner';
import CardContainer from './elements/CardContainer';
import PageLayout from 'components/PageLayout';
import Footer from 'components/Footer';

class Properties extends Component {
  state = {
    activeCategory: 'all',
  };

  handleCategorySelect = category => {
    this.setState({ activeCategory: category });
    window.history.pushState({}, null, `/properties/${category}`);
  };

  componentDidMount() {
    this.setState(
      { activeCategory: this.props.match.params.category },
      this.getFocus()
    );
  }

  getFocus = () => {
    if (
      window.innerWidth < 479 &&
      this.props.match.params.category === 'hospitality'
    ) {
      document.getElementById('categories').scrollLeft += 500;
    }
  };

  render() {
    return (
      <PageLayout>
        <Helmet>
          <meta
            name="description"
            content="Sunwins of has a proven track record of building quality homes in the Lower Mainland. From mixed commercial to agricultural, Sunwins commercial portfolio is diverse. Sunwins has an extensive portfolio of hospitality investments."
          />
          <title>Sunwins | Properties</title>
        </Helmet>
        <Intro />
        <CategorySelector
          activeCategory={this.state.activeCategory}
          handleSelection={this.handleCategorySelect}
        />
        <Banner activeCategory={this.state.activeCategory} />
        <CardContainer activeCategory={this.state.activeCategory} />
        <Footer />
      </PageLayout>
    );
  }
}

export default Properties;
