import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const Spacer = () => {
  return <Root />;
};
export default Spacer;

const Root = styled.div`
  height: ${vwMobile(80)};
  margin-bottom: -1px;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(80)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(150)};
  }
`;
