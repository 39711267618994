import React from 'react';
import styled from 'styled-components';

const GoldText = ({ children }) => {
  return <StyledText>{children}</StyledText>;
};

export default GoldText;

const StyledText = styled.span`
  color: ${({ theme }) => theme.colorGold};
`;
