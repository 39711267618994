import React from 'react';
import styled from 'styled-components';
import Reveal from 'react-reveal/Reveal';

import GoldText from 'components/GoldText';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const Intro = () => {
  return (
    <Root>
      <Reveal delay={200} effect="fadeInUp">
        <h1>
          <GoldText>Sunwins</GoldText> Properties
        </h1>
      </Reveal>
      <Reveal delay={300} effect="fadeInUp">
        <p>
          Our successful global track record for unique development speaks for
          itself. We have been delivering innovative architecture and providing
          owners with exceptional lifestyle environments for well over a decade.
        </p>
      </Reveal>
    </Root>
  );
};

export default Intro;

const Root = styled.div`
  background: ${({ theme }) => theme.colorNavy};
  padding-top: ${vwMobile(40)};
  padding-left: ${vwMobile(20)};
  padding-right: ${vwMobile(20)};
  padding-bottom: ${vwMobile(60)};
  h1 {
    margin-bottom: ${vwMobile(24)};
  }
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(60)};
    padding-left: ${vwTablet(30)};
    padding-bottom: ${vwTablet(60)};
    margin-bottom: -1px;
    h1,
    p {
      width: ${vwTablet(344)};
    }
    h1 {
      margin-bottom: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-top: ${vw(73)};
    padding-left: ${vw(106)};
    padding-bottom: ${vw(107)};
    h1 {
      margin-bottom: ${vw(30)};
    }
    h1,
    p {
      width: ${vw(636)};
    }
  }
`;
