import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const HeroImage = ({ image, title, description, backgroundAlignment }) => {
  return (
    <Root background={image} backgroundAlignment={backgroundAlignment}>
      <h1>{title}</h1>
      <h2>{description}</h2>
    </Root>
  );
};

export default HeroImage;

const Root = styled.div`
  height: ${vwMobile(420)};
  width: 100%;
  background: ${props =>
    `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.59)), url(${
      props.background
    })`};
  background-position: ${props =>
    props.backgroundAlignment ? props.backgroundAlignment : 'center'};
  background-size: cover;
  padding: ${vwMobile(24)} ${vwMobile(20)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  h1 {
    margin-bottom: ${vwMobile(8)};
  }
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(420)};
    padding: ${vwTablet(40)} ${vwTablet(30)};
    h1 {
      margin-bottom: ${vwTablet(8)};
    }
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(689)};
    padding: ${vw(51)} ${vw(104)};
  }
`;
