import React from 'react';
import styled from 'styled-components';

import Spacer from 'components/Spacer';

const PageLayout = ({ children, background }) => {
  return (
    <Root background={background}>
      <Spacer />
      {children}
    </Root>
  );
};

export default PageLayout;

const Root = styled.div`
  background: ${props => props.background && props.background};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
`;
