import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const CategorySelector = ({ activeCategory, handleSelection }) => {
  return (
    <Root>
      <CategoryContainer id="categories">
        <Category
          isActive={activeCategory === 'all'}
          onClick={() => handleSelection('all')}
          className="all"
        >
          All
        </Category>
        <Category
          isActive={activeCategory === 'residential'}
          onClick={() => handleSelection('residential')}
          className="residential"
        >
          Residential
        </Category>
        <Category
          isActive={activeCategory === 'commercial'}
          onClick={() => handleSelection('commercial')}
          className="commercial"
        >
          Commercial
        </Category>
        <Category
          isActive={activeCategory === 'hospitality'}
          onClick={() => handleSelection('hospitality')}
          className="hospitality"
        >
          Hospitality
        </Category>
      </CategoryContainer>
    </Root>
  );
};

export default CategorySelector;

const Root = styled.div`
  background: ${({ theme }) => theme.colorNavy};
  padding-left: ${vwMobile(20)};
  padding-right: ${vwMobile(20)};
  height: ${vwMobile(40)};
  margin-bottom: -1px;
  h2 {
    margin-right: ${vwMobile(24)};
    padding-bottom: ${vwMobile(12)};
    position: relative;
    &:last-of-type {
      margin-right: 0;
    }
  }
  @media (min-width: ${media.xsup}) {
    padding-left: ${vwTablet(30)};
    padding-right: ${vwTablet(30)};
    height: ${vwTablet(43)};
    h2 {
      padding-bottom: ${vwTablet(15)};
      margin-right: ${vwTablet(60)};
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-left: ${vw(104)};
    height: ${vw(45)};
    h2 {
      padding-bottom: ${vw(15)};
    }
  }
`;

const Category = styled.h2`
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    display: block;
    border-bottom: ${props => props.isActive && '4px solid white'};
    transform: ${props => (props.isActive ? 'scaleX(1)' : 'scaleX(0)')};
    transition: all 0.2s;
  }
`;

const CategoryContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow-x: scroll;
`;
