import React from 'react';
import styled from 'styled-components';

import PageLayout from 'components/PageLayout';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
import Footer from 'components/Footer';

const PrivacyPolicy = () => {
  return (
    <PageLayout background="white">
      <TextContainer>
        <h1>Privacy Policy</h1>
        <p>
          Sunwins Holdings collects personal information to better serve your
          needs, whether purchasing products and services from us or obtaining
          service or warranty work. In order to provide you with our wide
          variety of services, products and promotions, we may collect the
          following information:
          <br />
          <br />
          Contact and mailing information such as name, address, telephone
          numbers, fax numbers and email addresses. The plans, specifications
          and other particulars about the home that is being built for you.
          Municipal and legal descriptions for you home. Any other relevant
          information necessary to provide you with our products and services.
          We may need to collect other personal information depending on the
          circumstance, but we will ask you directly and ensure that you agree.
        </p>

        <h2>PERSONAL INFORMATION SECURITY</h2>
        <p>
          Like you, Sunwins Holdings values our personal information. Part of
          valuing your personal information is making sure that it is protected
          and kept confidential. We achieve this by: <br />
          Having our employees sign a confidentiality agreement. <br />
          Having up to date computer security such as passwords, encryption and
          firewalls. <br />
          Where third parties are hired to assist us with providing products and
          services to you for your home, we have them sign a privacy and
          confidentiality agreement to ensure that your personal information is
          protected by them.
        </p>
        <h2>PERSONAL INFORMATION UPDATING</h2>
        <p>
          Sunwins Holdings wants to ensure that your personal information is
          always correct up to date. If your personal information changes or is
          incorrect, updates can be made by contacting your representative with
          the change and any other information you feel is necessary.
          <br />
          <br />
          We will keep your information as long as is necessary to continue to
          meet your customer needs and any legal requirements we may have.
          However, once your personal information no longer needed, we will
          destroy it.
        </p>
        <h2>PERSONAL DATA</h2>
        <p>
          We only collect Personal Data from you, such as first and last name,
          phone number, e- mail, when you register to receive information. We
          collect data about your activities that does not personally or
          directly identify you when you visit our website, or the websites and
          online services where we display advertisements. This information may
          include the content you view, the date and time that you view this
          content, the products you purchase, or your location information
          associated with your IP address. We use the information we collect to
          serve you more relevant advertisements (referred to as “Retargeting”).
          We collect information about where you saw the ads we serve you and
          what ads you clicked on.
          <br />
          <br />
          As you browse www.Sunwinsproperties.ca, online ad networks we work
          with may place anonymous cookies on your computer, and use similar
          technologies, in order to understand your interests based on your
          (anonymous) online activities, and thus to tailor more relevant ads to
          you. If you do not wish to receive such tailored advertising, you can
          visit this page to opt out of most companies that engage in such
          advertising. (This will not prevent you from seeing ads; the ads
          simply will not be delivered through these targeting methods.)
          <br />
          <br />
          We do not target ads to you based on sensitive personal data, such as
          information related to race or ethnic Sunwins, political opinions,
          religious beliefs or other beliefs of a similar nature, trade union
          membership, physical or mental health or condition or sexual life. We
          may target ads based on data provided by Advertisers or partners alone
          or in combination with the data we collect ourselves. Any data used to
          serve targeted advertisements is de-identified and is not used to
          personally or directly identify you. In order to allow us to reach the
          best inventory online, we work with third party advertising companies
          (our “Advertising Partners”) to help us recognize you and serve
          relevant advertisements to you when you visit a website or online
          service in their network. We also work with Advertising Partners who
          help us recognize you across different devices in order to show you
          relevant advertisements. Our Advertising Partners may collect
          information about your activities on our website, and other websites
          or online services in their networks. We also work with third party
          companies to assist us with website analytics such as evaluating the
          use and operation of our website so that we can continue to enhance
          the website and our services.
          <br />
          <br />
          We and our third-party partners use session cookies and persistent
          cookies to make it easier for you to navigate and enhance the
          experience of our site, and to monitor and evaluate our website’s
          operation and use.
          <br />
          <br />
          To modify or delete your personal information, or to opt out of future
          communications, please contact us at:
          <br />
          <br />
          Sunwins Holdings #1205 - 8400 West Road
          <br />
          Richmond BC
          <br />
          V6X 0S7
          <br />
          <a
            href="mailto:info@sunwins.ca"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@sunwins.ca
          </a>
          <br />
        </p>
      </TextContainer>
      <Footer />
    </PageLayout>
  );
};

export default PrivacyPolicy;

const TextContainer = styled.div`
  padding-top: ${vwMobile(40)};
  padding-left: ${vwMobile(20)};
  padding-right: ${vwMobile(20)};
  padding-bottom: ${vwMobile(100)};
  h1,
  p,
  h2 {
    margin-bottom: ${vwMobile(24)};
  }
  h1,
  h2,
  p {
    color: ${({ theme }) => theme.colorCharcoal};
  }
  a {
    color: ${({ theme }) => theme.colorNavy};
    &:hover {
      color: ${({ theme }) => theme.colorGold};
    }
  }
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(60)};
    padding-left: ${vwTablet(30)};
    padding-right: ${vwTablet(30)};
    padding-bottom: ${vwTablet(120)};
    h1,
    p,
    h2 {
      margin-bottom: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-left: ${vw(104)};
    padding-right: ${vw(104)};
    padding-top: ${vw(120)};
    padding-bottom: ${vw(100)};
    h1,
    p,
    h2 {
      margin-bottom: ${vw(24)};
    }
    h1,
    p {
      width: ${vw(812)};
    }
  }
`;
