import React from 'react';
import styled from 'styled-components';

import CardCarousel from 'components/CardCarousel';
import CardGrid from 'components/CardGrid';
import Button from 'components/Button';
import GoldText from 'components/GoldText';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import properties from 'src/data/properties';

const projects = [
  properties.clubVersante,
  properties.internationalTradeCenter,
  properties.richmondOval,
];

const Projects = () => {
  return (
    <Root>
      <h1>
        Featured <GoldText> projects</GoldText>
      </h1>
      <CardCarousel properties={projects} />
      <CardGrid properties={projects} />
      <ButtonContainer>
        <Button path="/properties/all" label="All Projects" param="all" />
      </ButtonContainer>
    </Root>
  );
};

export default Projects;

const Root = styled.div`
  background: ${({ theme }) => theme.colorNavy};
  h1 {
    padding-left: ${vwMobile(20)};
    margin-bottom: ${vwMobile(-8)};
  }
  @media (min-width: ${media.xsup}) {
    h1 {
      margin-bottom: ${vwTablet(-18)};
      padding-left: ${vwTablet(30)};
    }
  }
  @media (min-width: ${media.mdup}) {
    h1 {
      padding-left: ${vw(104)};
      margin-bottom: ${vw(-16)};
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: ${vwMobile(60)};
  margin-top: ${vwMobile(-18)};
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(-10)};
    padding-bottom: ${vwTablet(60)};
  }
  @media (min-width: ${media.mdup}) {
    margin-top: ${vw(-34)};
    padding-bottom: ${vw(71)};
  }
`;
