import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
import GoldText from './GoldText';
import Button from './Button';

const ContactBanner = () => {
  return (
    <Root background={require('src/assets/images/misc/contact-banner.jpg')}>
      <TextContainer>
        <h1>
          <GoldText>Unforgettable</GoldText> hospitality experiences.{' '}
        </h1>
        <h1>
          <GoldText>Luxurious</GoldText> residences.
        </h1>
        <h1>
          Prime commercial <GoldText>opportunities</GoldText>.
        </h1>
      </TextContainer>
      <BackgroundGradient />
      <BackgroundImage
        background={require('src/assets/images/misc/contact-banner.jpg')}
      />

      <Button path="/get-in-touch" label="Contact Us" inverted="true" />
    </Root>
  );
};

export default ContactBanner;

const Root = styled.div`
  height: ${vwMobile(560)};
  padding: ${vwMobile(24)} ${vwMobile(20)};
  position: relative;
  h1 {
    color: ${({ theme }) => theme.colorCharcoal};
    margin-bottom: ${vwMobile(24)};

    &:last-of-type {
      margin-bottom: ${vwMobile(60)};
    }
  }
  @media (min-width: ${media.xsup}) {
    background: ${props => `url(${props.background})`};
    background-position: center;
    background-size: cover;
    height: ${vwTablet(298)};
    padding: ${vwTablet(40)} ${vwTablet(30)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h1 {
      margin-bottom: ${vwTablet(24)};
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  @media (min-width: ${media.mdup}) {
    z-index: 1;
    height: ${vw(479)};
    padding: ${vw(0)} ${vw(105)};

    h1 {
      margin-bottom: ${vw(25)};
      &:first-of-type {
        text-align: right;
      }
      &:nth-of-type(2) {
        text-align: center;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

const BackgroundGradient = styled.div`
  height: ${vwMobile(436)};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(241, 240, 241, 1) 70%,
    rgba(241, 240, 241, 0)
  );
  /* z-index: 1; */
  z-index: 2;
  @media (min-width: ${media.xsup}) {
    display: none;
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  bottom: ${vwMobile(0)};
  left: 0;
  height: ${vwMobile(285)};
  width: 100%;
  background: ${props => `url(${props.background})`};
  background-size: ${vwMobile(900)};
  background-position: right -50px bottom -150px;
  /* z-index: -2; */
  @media (min-width: ${media.xsup}) {
    display: none;
  }
`;

const TextContainer = styled.div`
  z-index: 4;
  position: relative;
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(344)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(734)};
  }
`;
