import React from 'react';
import styled from 'styled-components';
import Reveal from 'react-reveal/Reveal';

import { vwMobile, vwTablet, vw } from 'src/styles/utils';
import media from 'src/styles/media';
import MaskedLogo from '../elements/MaskedLogo';
import GoldText from 'components/GoldText';
import Button from 'components/Button';

const Landing = () => {
  return (
    <Root>
      <MaskedLogo />
      <TextContainer>
        <Reveal effect="fadeInUp" delay={500}>
          <h1>
            Founded on <GoldText>innovation</GoldText>.
          </h1>
        </Reveal>
        <Reveal effect="fadeInUp" delay={600}>
          <h1>
            Driven by <GoldText>trust</GoldText>.
          </h1>
        </Reveal>
        <Reveal effect="fadeInUp" delay={700}>
          <p>
            With a spirit of innovation and a legacy of trust, we create
            engaging environments and exceptional lifestyle experiences for our
            homeowners and clients.
          </p>
        </Reveal>
        <Reveal effect="fadeInUp" delay={800}>
          <Button path="/about" label="About Us" />
        </Reveal>
      </TextContainer>
    </Root>
  );
};

export default Landing;

const Root = styled.div`
  @media (min-width: ${media.xsup}) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    height: ${vwTablet(460)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(710)};
  }
`;

const TextContainer = styled.div`
  background: ${({ theme }) => theme.colorNavy};
  padding-left: ${vwMobile(20)};
  padding-right: ${vwMobile(20)};
  padding-bottom: ${vwMobile(60)};
  h1 {
    &:first-of-type {
      margin-bottom: ${vwMobile(12)};
    }
    &:nth-of-type(2) {
      margin-bottom: ${vwMobile(24)};
    }
  }
  p {
    margin-bottom: ${vwMobile(24)};
  }
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(82)};
    padding-left: ${vwTablet(30)};
    h1 {
      &:first-of-type {
        margin-bottom: ${vwTablet(12)};
      }
      &:nth-of-type(2) {
        margin-bottom: ${vwTablet(24)};
      }
    }
    p {
      margin-bottom: ${vwTablet(24)};
      width: ${vwTablet(344)};
    }
  }

  @media (min-width: ${media.mdup}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: ${vw(104)};
    padding-right: ${vw(170)};
    padding-top: ${vw(165)};
    flex: 1;

    h1 {
      &:first-of-type {
        margin-bottom: ${vw(15)};
      }
      &:nth-of-type(2) {
        margin-bottom: ${vw(22)};
      }
    }
    p {
      margin-bottom: ${vw(33)};
      width: auto;
    }
  }
`;
