import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
import Input from './elements/Input';
import Button from 'components/Button';

class Form extends Component {
  render() {
    return (
      <Root
        method="post"
        action="http://i.bamcommunications.ca/mailer/sunwins-mailer.php"
      >
        <Input label="First Name" type="text" name="first_name" required />
        <Input label="Last Name" type="text" name="last_name" required />

        <Input
          label="Email Address"
          type="text"
          name="email"
          required
          pattern="^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
        />

        <Input label="Phone Number" type="tel" name="tel" required />

        <Input label="Your Message" type="multiline" name="message" required />
        <input
          type="hidden"
          name="retURL"
          value={window.location.origin + '/thank-you'}
        />
        <Button type="submit" inverted />
      </Root>
    );
  }
}

export default Form;

const Root = styled.form`
  @media (min-width: ${media.xsup}) {
    display: flex;
    flex-direction: row;
    width: ${vwTablet(465)};
    flex-wrap: wrap;
  }
`;
