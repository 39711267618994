import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const Footer = () => {
  return (
    <Root>
      <ScrollTop
        src={require('src/assets/images/icons/scroll-top.png')}
        onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}
        alt="Scroll to Top"
      />
      <TopContainer>
        <Wordmark
          src={require('src/assets/images/logos/wordmark.png')}
          alt="Sunwins Wordmark"
        />

        <Links>
          <Properties>
            <h2>Properties</h2>
            <Link
              to={{ pathname: '/properties/residential', state: 'fromFooter' }}
            >
              Residential
            </Link>
            <Link
              to={{ pathname: '/properties/commercial', state: 'fromFooter' }}
            >
              Commercial
            </Link>
            <Link
              to={{ pathname: '/properties/hospitality', state: 'fromFooter' }}
            >
              Hospitality
            </Link>
          </Properties>

          <Company>
            <h2>Company</h2>
            <Link to="/about">About</Link>
            <Link to="/get-in-touch">Get in Touch</Link>
          </Company>

          <Legal>
            <h2>Legal</h2>
            <Link to="/terms-of-use">Terms of Use</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </Legal>
        </Links>
      </TopContainer>

      <BottomContainer>
        <Contact>
          <h2>Contact:</h2>
          <a
            href="https://www.google.com/maps/place/8400+W+Rd,+Richmond,+BC/data=!4m2!3m1!1s0x548674de56e0ce37:0x2a899fde588fc436?sa=X&ved=2ahUKEwj-0NyM__rhAhWBpZ4KHd2tAOsQ8gEwAHoECAoQAQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            #1205 - 8400 West Road<span>,</span>
            <br /> Richmond BC, V6X 0S7
          </a>
          <a
            href="mailto:info@sunwins.ca"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@sunwins.ca
          </a>
          <a href="tel:6042845366">604.284.5366</a>
        </Contact>

        <SocialMedia>
          {/* TODO: Add social media links */}
          <a href="#" target="_blank" rel="noopener noreferrer">
            <img
              src={require('src/assets/images/icons/facebook.png')}
              alt="Facebook"
            />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <img
              src={require('src/assets/images/icons/instagram.png')}
              alt="Instagram"
            />
          </a>
        </SocialMedia>
      </BottomContainer>
    </Root>
  );
};

export default Footer;

const Root = styled.div`
  background: white;
  padding-top: ${vwMobile(40)};
  padding-right: ${vwMobile(20)};
  padding-bottom: ${vwMobile(46)};
  padding-left: ${vwMobile(20)};
  position: relative;
  border-bottom: ${vwMobile(5)} solid ${({ theme }) => theme.colorNavy};

  a {
    color: white;
    transition: all 0.2s;
    &:last-of-type {
      margin-bottom: ${vwMobile(40)};
    }
    &:hover {
      color: ${({ theme }) => theme.colorGold};
    }
  }
  h2 {
    color: ${({ theme }) => theme.colorCharcoal};
    margin-bottom: ${vwMobile(16)};
  }
  @media (min-width: ${media.xsup}) {
    border-bottom: ${vwTablet(5)} solid ${({ theme }) => theme.colorNavy};
    padding-top: ${vwTablet(40)};
    padding-left: ${vwTablet(30)};
    padding-right: ${vwTablet(30)};
    padding-bottom: ${vwTablet(45)};
    h2 {
      margin-bottom: ${vwTablet(16)};
    }
  }
  @media (min-width: ${media.mdup}) {
    border-bottom: ${vw(5)} solid ${({ theme }) => theme.colorNavy};
    padding-top: ${vw(47)};
    padding-left: ${vw(182)};
    padding-right: ${vw(183)};
    padding-bottom: ${vw(30)};
    h2 {
      margin-bottom: ${vw(23)};
    }
  }
`;

const Wordmark = styled.img`
  height: ${vwMobile(86)};
  margin: 0 auto;
  margin-bottom: ${vwMobile(40)};
  display: block;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(86)};
    margin: 0;
    margin-right: ${vwTablet(47)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(85)};
    margin-right: ${vw(182)};
  }
`;

const TopContainer = styled.div`
  border-bottom: solid 1px #dbdae4;
  @media (min-width: ${media.xsup}) {
    display: flex;
    flex-direction: row;
  }
`;

const BottomContainer = styled.div`
  padding-top: ${vwMobile(16)};
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(24)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: ${media.mdup}) {
    padding-top: ${vw(29)};
    align-items: center;
  }
`;

const Links = styled.div`
  a {
    color: ${({ theme }) => theme.colorNavy};
    font-family: ${({ theme }) => theme.fontButton};
    font-size: ${vwMobile(13)};
    font-weight: normal;
    letter-spacing: ${vwMobile(1)};
    display: block;
    margin-bottom: ${vwMobile(20)};
  }
  @media (min-width: ${media.xsup}) {
    display: flex;
    flex-direction: row;
    > div {
      margin-right: ${vwTablet(80)};
      &:last-of-type {
        margin-right: 0;
      }
    }
    a {
      font-size: ${vwTablet(13)};
      letter-spacing: ${vwTablet(1)};
      margin-bottom: ${vwTablet(20)};
      &:last-of-type {
        margin-bottom: ${vwTablet(40)};
      }
    }
  }
  @media (min-width: ${media.mdup}) {
    > div {
      margin-right: ${vw(125)};
    }
    a {
      font-size: ${vw(13)};
      letter-spacing: ${vw(1)};
      margin-bottom: ${vw(20)};
      &:last-of-type {
        margin-bottom: ${vw(30)};
      }
    }
  }
`;

const Properties = styled.div``;

const Company = styled.div``;

const Legal = styled.div``;

const Contact = styled.div`
  h2 {
    padding-bottom: ${vwMobile(12)};
  }
  a {
    margin-bottom: ${vwMobile(12)};
    color: ${({ theme }) => theme.colorNavy};
    font-size: ${vwMobile(13)};
    font-family: ${({ theme }) => theme.fontButton};
    line-height: 1.38;
    letter-spacing: ${vwMobile(1)};
    font-weight: normal;
    display: block;
    &:last-of-type {
      margin-bottom: ${vwMobile(22)};
    }
  }
  span {
    display: none;
  }
  @media (min-width: ${media.xsup}) {
    padding-left: ${vwTablet(182)};
    h2 {
      margin-bottom: 0;
      padding-bottom: ${vwTablet(12)};
    }
    a {
      font-size: ${vwTablet(13)};
      margin-bottom: ${vwTablet(12)};
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  @media (min-width: ${media.mdup}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0;
    h2 {
      padding-bottom: 0;
      margin-bottom: 0;
      margin-right: ${vw(28)};
    }
    br {
      display: none;
    }
    a {
      font-size: ${vw(13)};
      letter-spacing: ${vw(1)};
      margin-bottom: 0;
      margin-right: ${vw(45)};
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    span {
      display: inline;
    }
  }
`;

const SocialMedia = styled.div`
  display: none !important;
  /* TODO: UNHIDE IF CLIENT GETS SOCIAL MEDIA LINKS */
  img {
    height: ${vwMobile(33)};
    margin-right: ${vwMobile(34)};
  }
  @media (min-width: ${media.xsup}) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    > a:last-of-type {
      margin-bottom: 0;
      > img {
        margin-right: ${vwTablet(72)};
      }
    }
    img {
      height: ${vwTablet(33)};
      margin-right: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.mdup}) {
    margin-left: auto;
    align-items: center;
    > a:last-of-type {
      img {
        margin-right: 0;
      }
    }
    img {
      height: ${vw(33)};
      margin-right: ${0};
      margin-left: ${vw(36)};
    }
  }
`;

const ScrollTop = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  height: ${vwMobile(36)};
  margin-right: ${vwMobile(20)};
  margin-bottom: ${vwMobile(45)};
  cursor: pointer;
  @media (min-width: ${media.xsup}) {
    top: ${vwTablet(328)};
    height: ${vwTablet(36)};
    right: 0;
    margin-right: ${vwTablet(156)};
  }
  @media (min-width: ${media.mdup}) {
    top: 0;
    right: 0;
    margin-right: ${vw(183)};
    margin-top: ${vw(51)};
    height: ${vw(36)};
  }
`;
