import { createGlobalStyle } from 'styled-components';
import theme from './theme';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  @keyframes fadeInUp {
    0% {
      display:none;
      opacity: 0;
      transform: translateY(60px);
    }
    1%{
      display:block;
      opacity: 0;
      transform: translateY(60px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .fadeInUp {
   animation-name: fadeInUp;
   animation-fill-mode: backwards;
 }



  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    font-family: 'Gotham';
    color: white;
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: ${theme.colorPrimary};
  }

  main {
    text-align: center;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h1 {
    font-size:${vwMobile(28)};
    font-weight:300;
    line-height: 1.21;
    letter-spacing: normal;
    @media(min-width:${media.xsup}){
    font-size:${vwTablet(28)};
    }
    @media(min-width:${media.mdup}){
      font-size:${vw(35)};
    }
  }

  h2 {
    font-size:${vwMobile(20)};
    font-weight:300;
    line-height:1.4;
    @media(min-width:${media.xsup}){
      font-size:${vwTablet(20)};
    }
    @media(min-width:${media.mdup}){
      font-size:${vw(22)};
    }
  }

  /* H6: for buttons */
  h6 {
    font-size:${vwMobile(13)};
    font-weight:normal;
    line-height:normal;
    letter-spacing:${vwMobile(1.5)};
    @media(min-width:${media.xsup}){
      font-size:${vwTablet(13)};
      letter-spacing:${vwTablet(1.5)};
    }
    @media(min-width:${media.mdup}){
      font-size:${vw(13)};
      letter-spacing:${vw(1.5)};
    }
  }

  p {
    font-size:${vwMobile(16)};
    font-weight:300;
    line-height:1.5;
    letter-spacing:normal;
    @media(min-width:${media.xsup}){
      font-size: ${vwTablet(17)};
    }
    @media(min-width:${media.mdup}){
      font-size:${vw(18)};
    }
  }


  .ril-caption-content {
    margin: 0 auto !important;
    /* margin-bottom:${vwMobile(167)} !important; */
    @media(min-width:${media.mdup}){
      /* margin-bottom:${vw(57)} !important; */
    }
  }

  .ril-prev-button, .ril-next-button{
    @media(max-width:${media.xs}){
      display:none;

    }
  }

  .ril-image-current{
    /* height:${vwMobile(161)};
    width: ${vwMobile(280)}; */
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/Roboto-Regular.woff2') format('woff2'),
      url('/assets/fonts/Roboto-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-Light.woff2') format('woff2'),
        url('/assets/fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-MediumItalic.woff2') format('woff2'),
        url('/assets/fonts/Gotham-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Gotham-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-LightItalic.woff2') format('woff2'),
        url('/assets/fonts/Gotham-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-Bold.woff2') format('woff2'),
        url('/assets/fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-Medium.woff2') format('woff2'),
        url('/assets/fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Book';
    src: url('/assets/fonts/Gotham-Book.woff2') format('woff2'),
        url('/assets/fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Book';
    src: url('/assets/fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('/assets/fonts/Gotham-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}


`;
