import React from 'react';
import styled from 'styled-components';
import Reveal from 'react-reveal/Reveal';

import GoldText from 'components/GoldText';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const AboutUs = () => {
  return (
    <Root>
      <HeroContainer>
        <img src={require('src/assets/images/about/about-hero.jpg')} />
      </HeroContainer>

      <Intro>
        <Reveal effect="fadeInUp" delay={200}>
          <h1>
            About <GoldText>Us</GoldText>
          </h1>
        </Reveal>
        <Reveal effect="fadeInUp" delay={300}>
          <p>
            We are a Vancouver based real estate and lifestyle brand developer
            that focuses on residential, commercial and hospitality projects.
          </p>
        </Reveal>
        <Reveal effect="fadeInUp" delay={400}>
          <p>
            Founded in 2002, we have built a reputation of continually
            delivering innovative architectural solutions, engaging
            environments, and exceptional lifestyle experiences for our
            homeowners and clients.
          </p>
        </Reveal>
      </Intro>
      <Vision>
        <Reveal effect="fadeInUp" delay={200}>
          <h1>
            Our <GoldText>Vision</GoldText>
          </h1>
        </Reveal>
        <Reveal effect="fadeInUp" delay={300}>
          <p>
            Whether it is an unforgettable hospitality experience, luxurious
            residence, or prime commercial opportunity, our philosophy in
            everything we do is based on applying the highest standards to the
            naturally spectacular world around us – creating projects and
            experiences that are uniquely British Columbian.
          </p>
        </Reveal>
      </Vision>
    </Root>
  );
};

export default AboutUs;

const Root = styled.div`
  background: ${({ theme }) => theme.colorNavy};
  padding-top: ${vwMobile(40)};
  padding-left: ${vwMobile(20)};
  padding-right: ${vwMobile(20)};
  padding-bottom: ${vwMobile(120)};
  position: relative;
  p {
    margin-top: ${vwMobile(24)};
    &:nth-of-type(2) {
      font-size: ${vwMobile(14)};
      line-height: 1.5;
    }
  }
  @media (min-width: ${media.xsup}) {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: ${vwTablet(40)};
    padding-left: ${vwTablet(30)};
    padding-right: ${vwTablet(30)};
    padding-bottom: ${vwTablet(40)};
    p {
      &:first-of-type {
        margin-top: ${vwTablet(24)};
      }
      &:nth-of-type(2) {
        margin-top: ${vwTablet(24)};
        font-size: ${vwTablet(14)};
      }
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-top: ${vw(141)};
    padding-left: ${vw(104)};
    padding-right: ${vw(135)};
    padding-bottom: ${vw(69)};
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    h1:first-of-type {
      padding-top: ${vw(10)};
    }
    p {
      &:first-of-type {
        margin-top: ${vw(30)};
      }
      &:nth-of-type(2) {
        margin-top: ${vw(23)};
        font-size: ${vw(15)};
      }
    }
  }
`;

const Intro = styled.div`
  margin-bottom: ${vwMobile(190)};
  position: relative;
  z-index: 3;
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(344)};
    margin-bottom: ${vwTablet(156)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(598)};
    margin-bottom: ${vw(334)};
  }
`;

const Vision = styled.div`
  position: relative;
  z-index: 3;
  @media (min-width: ${media.xsup}) {
    margin-left: auto;
    width: ${vwTablet(344)};
    p {
      font-size: ${vwTablet(14)};
      line-height: 1.5;
    }
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(495)};
    p {
      font-size: ${vw(15)};
      line-height: 1.6;
      letter-spacing: normal;
      font-weight: 300;
    }
  }
`;

const HeroContainer = styled.div`
  position: absolute;
  left: ${vwMobile(60)};
  top: ${vwMobile(308)};
  height: ${vwMobile(300)};
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media (min-width: ${media.xsup}) {
    top: ${vwTablet(190.6)};
    left: ${vwTablet(272)};
    height: ${vwTablet(333)};
  }
  @media (min-width: ${media.mdup}) {
    top: ${vw(141)};
    left: ${vw(374)};
    height: ${vw(716)};
  }
`;
