import React from 'react';
import styled from 'styled-components';

const Container = props => {
  return <Root {...props} />;
};

const Root = styled.div`
  position: relative;
  max-width: 1440px;
  max-width: ${({ theme }) => theme.containerWidth};
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
`;

export default Container;