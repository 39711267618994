import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
import Button from 'components/Button';

const AboutProject = ({
  description,
  location,
  images,
  url,
  handleLightbox,
  locationLink,
}) => {
  return (
    <Root>
      <LeftColumn>
        <Description>
          <h6>About this Project</h6>

          <p>{description}</p>
        </Description>
        {location && (
          <Location hasLink={locationLink}>
            <h6>Location</h6>
            <a
              href={locationLink || null}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>{location}</p>
            </a>
          </Location>
        )}

        {url && (
          <ButtonContainer>
            <Button label="Visit Website" url={url} inverted />
          </ButtonContainer>
        )}
      </LeftColumn>
      <RightColumn>
        <Gallery>
          <h6>Gallery</h6>
          <GalleryImages>
            {images.map((image, index) => (
              <ImageContainer
                key={index}
                onClick={() => handleLightbox(true, index)}
                background={image}
              />
            ))}
          </GalleryImages>
        </Gallery>
      </RightColumn>
    </Root>
  );
};

export default AboutProject;

const Root = styled.div`
  background: ${({ theme }) => theme.colorLightGray};
  padding-top: ${vwMobile(40)};
  padding-left: ${vwMobile(20)};
  padding-right: ${vwMobile(20)};
  padding-bottom: ${vwMobile(24)};
  h6 {
    color: ${({ theme }) => theme.colorCharcoal};
    font-size: ${vwMobile(13)};
    line-height: 1.77;
    letter-spacing: ${vwMobile(1.5)};
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${vwMobile(12)};
  }
  p {
    font-size: ${vwMobile(14)};
    font-weight: 300;
    line-height: 1.5;
    color: ${({ theme }) => theme.colorCharcoal};
    margin-bottom: ${vwMobile(24)};
  }
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(40)};
    padding-left: ${vwTablet(30)};
    padding-right: ${vwTablet(30)};
    padding-bottom: ${vwTablet(40)};
    h6 {
      font-size: ${vwTablet(13)};
      letter-spacing: ${vwTablet(1.5)};
      margin-bottom: ${vwTablet(12)};
    }
    p {
      font-size: ${vwTablet(14)};
      margin-bottom: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-top: ${vw(45)};
    padding-left: ${vw(104)};
    padding-right: ${vw(104)};
    padding-bottom: ${vw(50)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h6 {
      font-size: ${vw(13)};
      letter-spacing: ${vw(1.5)};
      margin-bottom: ${vw(14)};
    }
    p {
      font-size: ${vw(15)};
      margin-bottom: ${vw(27)};
    }
  }
`;

const Description = styled.div``;

const Location = styled.div`
  a,
  p {
    display: inline-block;
    &:hover {
      color: ${props => props.hasLink && props.theme.colorGold};
      transition: color 0.2s;
    }
  }
`;

const Gallery = styled.div``;

const GalleryImages = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ImageContainer = styled.div`
  height: ${vwMobile(78)};
  width: ${vwMobile(78)};
  margin-right: ${vwMobile(16)};
  margin-bottom: ${vwMobile(16)};
  cursor: pointer;

  /* background-image: ${props =>
    `linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0)), linear-gradient(to bottom, #f7f7f7, #f7f7f7), url(${
      props.background
    })`}; */

  background-image: ${props =>
    `linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0)),url(${
      props.background
    })`};
  background-size: cover;
  background-position: center;
  /* background-blend-mode: multiply; */
  &:nth-child(3n) {
    margin-right: 0;
  }
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(78)};
    width: ${vwTablet(78)};
    margin-right: ${vwTablet(16)};
    margin-bottom: ${vwTablet(16)};
    &:nth-child(3n) {
      margin-right: ${vwTablet(16)};
    }
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(78)};
    width: ${vw(78)};
    margin-right: ${vw(27)};
    margin-bottom:${vwTablet(27)};
    &:nth-child(3n) {
      margin-right: ${vw(27)};
    }
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ButtonContainer = styled.div`
  margin-bottom: ${vwMobile(24)};
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${vwTablet(24)};
  }
  @media (min-width: ${media.mdup}) {
    margin-bottom: 0;
  }
`;

const LeftColumn = styled.div`
  @media (min-width: ${media.mdup}) {
    width: ${vw(524)};
  }
`;

const RightColumn = styled.div`
  @media (min-width: ${media.mdup}) {
    width: ${vw(602)};
  }
`;
