import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { hot } from 'react-hot-loader';

import { prerenderStyles } from 'src/plugins/prerender';
import routes from 'src/routes';
import { ParallaxProvider } from 'react-scroll-parallax';

import Header from 'components/Header/Header';
import Footer from 'components/Footer';

class App extends Component {
  componentDidMount() {
    prerenderStyles();
  }

  generateRoutes() {
    return routes.map((route, index) => {
      const { path, component } = route;
      const Component = require(`src/${component}`).default;
      return <Route exact path={path} component={Component} key={index} />;
    });
  }

  render() {
    return (
      <ParallaxProvider>
        <Route
          render={({ location }) => (
            <AppWrapper>
              <Header />
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={500}
                  classNames="fade"
                >
                  <Switch location={location}>{this.generateRoutes()}</Switch>
                </CSSTransition>
              </TransitionGroup>

              {/* <Footer /> */}
            </AppWrapper>
          )}
        />
      </ParallaxProvider>
    );
  }
}

const AppWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  .fade-enter {
    opacity: 0;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 0.5s;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: all 0.5s;
  }
`;

export default hot(module)(App);
