import React from 'react';
import styled from 'styled-components';
import Reveal from 'react-reveal/Reveal';

import { vw, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';
import PropertyCard from 'components/PropertyCard';

const CardGrid = ({ properties }) => {
  return (
    <Root>
      {properties.map((property, index) => (
        <Reveal
          effect="fadeInUp"
          delay={200 * ((index + 3) % 3)}
          key={property.title + index}
        >
          <PropertyCard property={property} />
        </Reveal>
      ))}
    </Root>
  );
};

export default CardGrid;

const Root = styled.div`
  display: none;
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(34)};
    padding-left: ${vwTablet(30)};
    padding-right: ${vwTablet(30)};
    padding-bottom: ${vwTablet(30)};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    > div {
      &:nth-child(3n) a {
        margin-right: 0;
      }
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-left: ${vw(104)};
    padding-right: ${vw(104)};
    padding-bottom: ${vw(60)};
    padding-top: ${vw(34)};
  }
`;
