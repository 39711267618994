export default [
  {
    title: 'Residential',
    path: '/properties/residential',
    param: 'residential',
  },
  {
    title: 'Commercial',
    path: '/properties/commercial',
    param: 'commercial',
  },
  {
    title: 'Hospitality',
    path: '/properties/hospitality',
    param: 'hospitality',
  },
  {
    title: 'About',
    path: '/about',
  },
  {
    title: 'Get In Touch',
    path: '/get-in-touch',
  },
];
