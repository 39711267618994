import React from 'react';
import styled from 'styled-components';

import GoldText from 'components/GoldText';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const Banner = ({ activeCategory }) => {
  let formattedTitle =
    activeCategory &&
    activeCategory.charAt(0).toUpperCase() + activeCategory.slice(1);
  let activeBanner =
    activeCategory && 'header-' + activeCategory + '-properties';
  return (
    <Root background={activeBanner}>
      <h1>
        <GoldText>{formattedTitle}</GoldText> Properties
      </h1>
    </Root>
  );
};

export default Banner;

const Root = styled.div`
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  background: ${props =>
    props.background &&
    `url(${require(`src/assets/images/properties/${
      props.background
    }-mobile.jpg`)})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-left: ${vwMobile(20)};
  padding-right: ${vwMobile(20)};
  padding-bottom: ${vwMobile(20)};
  height: ${vwMobile(160)};
  display: flex;
  align-items: flex-end;
  transition: all 0.2s;
  h1 {
    margin-top: auto;
    display: block;
  }
  @media (min-width: ${media.xsup}) {
    background: ${props =>
      props.background &&
      `url(${require(`src/assets/images/properties/${
        props.background
      }.jpg`)})`};
    background-size: contain;
    height: ${vwTablet(112)};
    align-items: center;
    padding-left: ${vwTablet(30)};
    padding-bottom: ${vwTablet(39)};
  }
  @media (min-width: ${media.mdup}) {
    padding-left: ${vw(104)};
    height: ${vw(210)};
  }
`;
