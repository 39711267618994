import React from 'react';
import styled from 'styled-components';
import Reveal from 'react-reveal/Reveal';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
import GoldText from 'components/GoldText';
import Button from 'components/Button';
import PageLayout from 'components/PageLayout';
import Footer from 'components/Footer';

const ThankYou = () => {
  return (
    <PageLayout background={({ theme }) => theme.colorNavy}>
      <TextContainer>
        <Reveal effect="fadeInUp" delay={200}>
          <h1>
            Thank <GoldText>You</GoldText>
          </h1>
        </Reveal>
        <Reveal effect="fadeInUp" delay={300}>
          <p>
            Thank you for getting in touch. We'll get back to you as soon as we
            can.
          </p>
        </Reveal>
        <Reveal effect="fadeInUp" delay={400}>
          <Button label="Back to Home" path="/" />
        </Reveal>
      </TextContainer>
      <Footer />
    </PageLayout>
  );
};

export default ThankYou;

const TextContainer = styled.div`
  padding-top: ${vwMobile(40)};
  padding-left: ${vwMobile(20)};
  padding-right: ${vwMobile(20)};
  padding-bottom: ${vwMobile(100)};
  h1 {
    margin-bottom: ${vwMobile(24)};
  }
  p {
    margin-bottom: ${vwMobile(40)};
  }
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(60)};
    padding-left: ${vwTablet(30)};
    padding-right: ${vwTablet(30)};
    padding-bottom: ${vwTablet(120)};
    h1 {
      margin-bottom: ${vwTablet(24)};
    }
    p {
      margin-bottom: ${vwTablet(40)};
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-top: ${vw(151)};
    padding-left: ${vwTablet(104)};
    padding-bottom: ${vwTablet(261)};
    h1,
    p {
      width: ${vw(497)};
    }
    h1 {
      margin-bottom: ${vw(22)};
    }
    p {
      margin-bottom: ${vw(36)};
    }
  }
`;
