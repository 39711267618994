import React, { Component } from 'react';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import PageLayout from 'components/PageLayout';
import NavigationBar from './elements/NavigationBar';
import HeroImage from './sections/HeroImage';
import AboutProject from './sections/AboutProject';
import Overview from './sections/Overview';
import QuickLinks from './sections/QuickLinks';
import Footer from 'components/Footer';

import properties from 'src/data/properties';

class IndividualProperty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  handleLightbox = (bool, index) => {
    this.setState({ isOpen: bool, photoIndex: index });
  };

  render() {
    const { photoIndex, isOpen } = this.state;

    const { category, property } = this.props.match.params;
    const propertyData = Object.entries(properties).find(
      ([index, data]) => data.link === property
    )[1];

    return (
      <PageLayout>
        <NavigationBar category={category} title={propertyData.title} />
        <HeroImage
          image={
            propertyData.images
              ? propertyData.images[0]
              : require('src/assets/properties/sunwins_placeholder.jpg')
          }
          title={propertyData.title}
          description={propertyData.description}
          backgroundAlignment={propertyData.backgroundAlignment}
        />
        <AboutProject
          description={
            propertyData.extendedDescription
              ? propertyData.extendedDescription
              : propertyData.description
          }
          images={
            propertyData.images
              ? propertyData.images
              : [require('src/assets/properties/sunwins_placeholder.jpg')]
          }
          location={propertyData.location ? propertyData.location : ''}
          locationLink={
            propertyData.locationLink ? propertyData.locationLink : ''
          }
          url={propertyData.url}
          handleLightbox={this.handleLightbox}
        />

        <Overview
          type={propertyData.overviewType}
          status={propertyData.status}
        />

        <QuickLinks />
        <Footer />

        {isOpen && propertyData.images && (
          <Lightbox
            mainSrc={propertyData.images[photoIndex]}
            nextSrc={
              propertyData.images[(photoIndex + 1) % propertyData.images.length]
            }
            prevSrc={
              propertyData.images[
                (photoIndex + propertyData.images.length - 1) %
                  propertyData.images.length
              ]
            }
            imagePadding={20}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + propertyData.images.length - 1) %
                  propertyData.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % propertyData.images.length,
              })
            }
            imageCaption={`${this.state.photoIndex + 1}/${
              propertyData.images.length
            }`}
          />
        )}
      </PageLayout>
    );
  }
}

export default IndividualProperty;
