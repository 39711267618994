import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import PageLayout from 'components/PageLayout';
import ContactBanner from 'components/ContactBanner';
import Footer from 'components/Footer';
import AboutUs from './sections/AboutUs';
import Promise from './sections/Promise';

class About extends Component {
  componentDidMount() {
    this.handleResize();
    addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    this.handleResize();
    removeEventListener('resize', this.handleResize);
  }

  state = {
    disabled: true,
  };

  handleResize = () => {
    if (window.innerWidth > 992) {
      this.setState({ disabled: false });
    } else this.setState({ disabled: true });
  };
  render() {
    return (
      <PageLayout>
        <Helmet>
          <meta
            name="description"
            content="We are a Vancouver based real estate and lifestyle brand developer that focuses on residential, commercial and hospitality projects."
          />
          <title>Sunwins | About Us</title>
        </Helmet>
        <AboutUs />
        <Promise parallaxDisabled={this.state.disabled} />
        <ContactBanner />
        <Footer />
      </PageLayout>
    );
  }
}

export default About;
